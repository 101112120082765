import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  CommentCount?: number;
  postId?: number;
  eventId?: number;
  flagCategory?: {
    "id": number,
    "reason": string,
    "created_at": string,
    "updated_at": string
}[],
  getAllDataCallBack?: () => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  commentData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  comment: string;
  commentsArray: any;
  replyCommentId: any;
  newComment: string;
  openModal: boolean;
  allComments: [];
  deleteCommentModalOpen: boolean;
  commentError: string;
  commentId: number;
  isReply: boolean;
  toastOpen: boolean;
  toastMessage: string;
  profileUrl: string;
  isReplyDelete: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CommentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCommentItemCallId: string = "";
  commentApiCallId: string = "";
  createCommentId: string = "";
  likeCommentId: string = "";
  getCommentPostApiKeyCallId: string = "";
  commentPostApiKeyCallId: string = "";
  deleteCommentApiKeyCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      commentData: [],
      errorMsg: "",
      token: "",
      loading: false,
      comment: "",
      commentsArray: [],
      replyCommentId: null,
      newComment: "",
      openModal: false,
      allComments: [],
      deleteCommentModalOpen: false,
      commentError: "",
      commentId: 0,
      isReply: false,
      toastOpen: false,
      toastMessage: "",
      profileUrl: "",
      isReplyDelete: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    } else {
      this.getToken();
    }
    // Customizable Area Start
    this.getProfile();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getCommentPostApiKeyCallId:
          this.getCommentPostApiKeyCallIdApi(responseJson);
          break;

        case this.commentPostApiKeyCallId:
          this.commentPostApiKeyCallIdApi(responseJson);
          break;

        case this.deleteCommentApiKeyCallId:
          this.deleteCommentApiKeyCallIdApi(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setComment = (comment: string) => {
    this.setState({ comment });
  };

  createComment = (id?: any, postId?: any) => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };

    const data = {
      attributes: {
        post_id: postId ? postId : 1,
        comment: this.state.comment,
      },
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  likeChildComments = (commentId: any) => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };
    const data = {
      attributes: {
        comment_id: commentId,
      },
    };
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likeCommentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCommentText(comment: any) {
    try {
      return JSON.parse(comment.replace("=>", ":"))?.text;
    } catch {
      return comment;
    }
  }

  getCommentPostApiKeyCallIdApi = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ allComments: responseJson.data, isReply: false });
    }
  };

  commentPostApiKeyCallIdApi = (responseJson: any) => {
    if (!responseJson.errors) {
      const { postId, eventId } = this.props;
      this.setState({ newComment: "" });
      if (eventId) {
        this.handleGetComment(eventId);
      } else if (postId) {
        this.handleGetComment(postId);
      }
      this.props.getAllDataCallBack && this.props.getAllDataCallBack();
    } else {
      this.setState({ commentError: responseJson.errors[0].comment });
    }
  };

  deleteCommentApiKeyCallIdApi = (responseJson: any) => {
    const { postId, eventId } = this.props;
    if (responseJson.message) {
      this.setState({
        deleteCommentModalOpen: false,
        toastOpen: true,
        toastMessage: responseJson.message,
        openModal: this.state.isReplyDelete,
      });
      if (eventId) {
        this.handleGetComment(eventId);
      } else if (postId) {
        this.handleGetComment(postId);
      }
      this.props.getAllDataCallBack && this.props.getAllDataCallBack();
    }
  };

  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    request.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"? request.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body))
    : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleCommentChange = (event: any) => {
    this.setState({ newComment: event.target.value, commentError: "" });
  };

  handleOpenModal = () => {
    const { postId, eventId } = this.props;
    this.setState({ openModal: !this.state.openModal });
    const id = postId || eventId;
    if (id !== undefined) this.handleGetComment(id);
  };

  handleGetComment = async (postId: number) => {
    const commentableType = this.props.eventId
      ? "BxBlockLocation::Event"
      : "BxBlockPosts::Post";
    this.getCommentPostApiKeyCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_comments/comments/?commentable_type=${commentableType}&commentable_id=${postId}`,
      contentType: "application/json",
    });
  };

  handleCommentPost = async (postId?: any, commentId?: any) => {
    const { newComment } = this.state;
    const { eventId } = this.props;
    const body = {
      comment: {
        comment: newComment,
        commentable_type: eventId
          ? "BxBlockLocation::Event"
          : "BxBlockPosts::Post",
        commentable_id: postId,
        ...(commentId && { parent_id: commentId }),
      },
    };

    this.commentPostApiKeyCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_comments/comments",
      contentType: "application/json",
      body,
    });
  };

  handleDeleteComment = async () => {
    const { commentId } = this.state;
    this.deleteCommentApiKeyCallId = await this.apiCall({
      method: "DELETE",
      endPoint: `bx_block_comments/comments/${commentId}`,
      contentType: "application/json",
    });
  };

  handleCommentDeleteDoneModal = (commentId?: any, isReplyDelete?: any) => {
    this.setState({
      deleteCommentModalOpen: !this.state.deleteCommentModalOpen,
      commentId,
      isReplyDelete,
    });
  };

  handleReply = (commentId?: any) => {
    this.setState({
      isReply: true,
      commentId,
      newComment: "",
      commentError: "",
    });
  };

  handleReplyCancel = () =>
    this.setState({ isReply: false, commentError: "", newComment: "" });

  handleCloseToast = () => this.setState({ toastOpen: false });

  getProfile = async () => {
    const profileUrl = await getStorageData("profile");
    this.setState({ profileUrl });
  };

  // Customizable Area End
}
