export const event = require("../assets/event.png");
export const uncheckbox = require("../assets/uncheckbox.png");
export const checkbox = require("../assets/checkbox.png");
export const add = require("../assets/add.png");
export const map = require("../assets/map.png");
export const search = require("../assets/search.png");
export const privateIcon = require("../assets/privateIcon.png");
export const publicIcon = require("../assets/publicIcon.png");
export const rsvp = require("../assets/rsvp.png");
export const disabledRsvp = require("../assets/disabledRsvp.png");
export const shareAction = require("../assets/shareAction.png");
export const flag = require("../assets/flag.png");
export const like = require("../assets/like.png");
export const comment = require("../assets/chat.png");
export const drop = require("../assets/drop.png");
export const uploadBrowse = require("../assets/uploadBrowse.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const back = require("../assets/back.png");
export const markerIcon = require("../assets/marker.png");
export const calenderIcon = require("../assets/calenderIcon.png");