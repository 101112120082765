import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { setStorageData, getStorageData } from "framework/src/Utilities";

type Nullable<T> = T | null;
interface PrivacyObject {
  consent_to: string;
  access_level: Nullable<string>;
  consent_for: string;
  status?: string;
  id?: number;
  account_id?: number;
  created_by?: null;
  updated_by?: Nullable<string>;
  is_active?: boolean;
  created_at?: string;
  updated_at?: string;
}

export type responseJson = {
  message: string;
  privacy_setting: PrivacyObject[];
  errors?: string;
  meta: {
    token: string;
    refresh_token: string;
    id: number;
  };
};
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: NavigationProp<ParamListBase>;
  id: string;
  // Customizable Area Start
  privacyKey?: string,
  updatePrivacyKey?: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  postChecked: boolean;
  videoChecked: boolean;
  photoChecked: boolean;
  postConsent: Array<PrivacyObject>;
  photoConsent: Array<PrivacyObject>;
  videoConsent: Array<PrivacyObject>;
  token: string;
  accountID: string;
  noData: boolean;
  isUpdating: boolean;
  updatedSettings: Array<PrivacyObject>;
  getPrivacyKey: string,
  showNotification: boolean,
  updateMessage: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacySettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginApiCallId: string = "";

  createPrivacySettingsCallId: string = "";

  updatePrivacySettingsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      postChecked: false,
      videoChecked: false,
      photoChecked: false,
      postConsent: [],
      photoConsent: [],
      videoConsent: [],
      token: "",
      accountID: "",
      noData: false,
      isUpdating: false,
      getPrivacyKey: props.privacyKey || "",
      updatedSettings: [],
      showNotification: false,
      updateMessage: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.updatePrivacySettingsCallId) {
        this.handlePrivacyData(responseJson)
      }

      if (
        apiRequestCallId === this.loginApiCallId &&
        !responseJson.errors
      ) {
        await setStorageData("TOKEN", responseJson.meta.token);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handlePrivacyData = (responseJson: any) => {
    this.setState({showNotification: true, updateMessage: responseJson.message })
    if (responseJson.privacy_setting) {
      this.setState({ getPrivacyKey: responseJson.privacy_setting?.data?.attributes.is_account }); 
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log('Errors ', responseJson.errors)
    }
  };

  createPrivacySettings = () => {
      const body = {
        data: {
          account_id: this.state.accountID,
          data: [
            ...this.state.photoConsent,
            ...this.state.postConsent,
            ...this.state.videoConsent,
          ],
        },
      };
      const header = {
        "Accept": configJSON.validationApiContentType,
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );
  
      this.createPrivacySettingsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createPrivacySettings,
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header),
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiPOSTMethodType,
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body),
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };


  updatePrivacySettings = () => {
    const body = {
      data: {
        data: this.state.updatedSettings,
      },
    };
    const header = {
      "Accept": configJSON.validationApiContentType,
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.updatePrivacySettingsCallId = requestMessage.messageId;
    this.loginApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePrivacySettings,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPUTMethodType,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createButtonButtonProps = {
    onPress: () =>
      this.state.photoChecked &&
      this.state.videoChecked &&
      this.state.postChecked
        ? this.createPrivacySettings()
        : this.showAlert(configJSON.allDataErrorMessage, configJSON.emptyText),
  };

  updateButtonButtonProps = {
    onPress: () =>
      this.state.isUpdating
        ? this.updatePrivacySettings()
        : this.setState({ isUpdating: true }),
  };

  postConsentPickerProps = (index: number) => (itemValue: string) => {
    const postArray: Array<PrivacyObject> = this.state.postConsent;
    if (
      postArray.filter((item: PrivacyObject) => item.consent_to === itemValue)
        .length === 0
    ) {
      postArray[index].consent_to = itemValue;
      this.setState({ postConsent: postArray }, () => {
        this.state.isUpdating &&
          this.setState((prevState: S) => ({
            updatedSettings: prevState.updatedSettings.concat(postArray[index]),
          }));
      });
    } else {
      this.showAlert(
        `${itemValue} ${configJSON.alreadySelectedText}`,
        configJSON.emptyText,
      );
    }
  };

  postLevelPickerProps = (index: number) => (itemValue: string) => {
    const postArray: Array<PrivacyObject> = this.state.postConsent;
    postArray[index].access_level = itemValue;

    this.setState(
      { postConsent: postArray },
      () =>
        this.state.isUpdating &&
        this.setState((prevState: S) => ({
          updatedSettings: prevState.updatedSettings.concat(postArray[index]),
        })),
    );
  };

  imageConsentPickerProps = (index: number) => (itemValue: string) => {
    const photoArray: Array<PrivacyObject> = this.state.photoConsent;
    if (
      photoArray.filter((item: PrivacyObject) => item.consent_to === itemValue)
        .length === 0
    ) {
      photoArray[index].consent_to = itemValue;
      this.setState(
        { photoConsent: photoArray },
        () =>
          this.state.isUpdating &&
          this.setState((prevState: S) => ({
            updatedSettings: prevState.photoConsent.concat(photoArray[index]),
          })),
      );
    } else {
      this.showAlert(
        `${itemValue} ${configJSON.alreadySelectedText}`,
        configJSON.emptyText,
      );
    }}

    imageLevelPickerProps = (index: number) => (itemValue: string) => {
      const photoArray: Array<PrivacyObject> = this.state.photoConsent;
      photoArray[index].access_level = itemValue;
      this.setState(
        { photoConsent: photoArray },
        () =>
          this.state.isUpdating &&
          this.setState((prevState: S) => ({
            updatedSettings: prevState.photoConsent.concat(photoArray[index]),
          })),
      );
    };

  updatePrivacyData = async () => {
    this.updatePrivacySettingsCallId = await this.contentModerationApiCall({
      method: "PATCH",
      endPoint: "bx_block_privacy_setting/privacy_settings/update_user_privacy_settings",
      contentType: "application/json",
      body: {
        "data": {
          "is_account": this.state.getPrivacyKey
        }
      }
    });
  };

  handleNotification = () => {
    this.setState({showNotification: false, updateMessage: ""})
  }

  videoConsentPickerProps = (index: number) => (itemValue: string) => {
    const videoArray: Array<PrivacyObject> = this.state.videoConsent;
    if (
      videoArray.filter((item: PrivacyObject) => item.consent_to === itemValue)
        .length === 0
    ) {
      videoArray[index].consent_to = itemValue;
      this.setState(
        { videoConsent: videoArray },
        () =>
          this.state.isUpdating &&
          this.setState((prevState: S) => ({
            updatedSettings: prevState.videoConsent.concat(videoArray[index]),
          })),
      );
    } else {
      this.showAlert(
        `${itemValue} ${configJSON.alreadySelectedText}`,
        configJSON.emptyText,
      );
    }}

  videoLevelPickerProps = (index: number) => (itemValue: string) => {
    const videoArray: Array<PrivacyObject> = this.state.videoConsent;
    videoArray[index].access_level = itemValue;
    this.setState(
      { videoConsent: videoArray },
      () =>
        this.state.isUpdating &&
        this.setState((prevState: S) => ({
          updatedSettings: prevState.videoConsent.concat(videoArray[index]),
        })),)}

  contentModerationApiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  videoSwitchProps = {
    onValueChange: (value: boolean) =>
      this.state.noData
        ? this.setState({
            videoChecked: value,
            videoConsent: [
              {
                consent_to: "friends",
                access_level: null,
                consent_for: "videos",
                status: "granted",
              },
              {
                consent_to: "family",
                access_level: null,
                consent_for: "videos",
                status: "granted",
              },
              {
                consent_to: "anyone",
                access_level: null,
                consent_for: "videos",
                status: "granted",
              },
            ],
          })
        : this.setState({
            videoChecked: value,
          }),
  };

  imageSwitchProps = {
    onValueChange: (value: boolean) =>
      this.state.noData
        ? this.setState({
            photoChecked: value,
            photoConsent: [
              {
                consent_to: "friends",
                access_level: null,
                consent_for: "photos",
                status: "granted",
              },
              {
                consent_to: "family",
                access_level: null,
                consent_for: "photos",
                status: "granted",
              },
              {
                consent_to: "anyone",
                access_level: null,
                consent_for: "photos",
                status: "granted",
              },
            ],
          })
        : this.setState({
            photoChecked: value,
          }),
  };

  postSwitchProps = {
    onValueChange: (value: boolean) =>
      this.state.noData
        ? this.setState({
            postChecked: value,
            postConsent: [
              {
                consent_to: "friends",
                access_level: null,
                consent_for: "posts",
                status: "granted",
              },
              {
                consent_to: "family",
                access_level: null,
                consent_for: "posts",
                status: "granted",
              },
              {
                consent_to: "anyone",
                access_level: null,
                consent_for: "posts",
                status: "granted",
              },
            ],
          })
        : this.setState({
            postChecked: value,
          }),
  };
  // Customizable Area End
}
