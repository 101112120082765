import {
  Box,
  Button,
  Typography,
  styled,
  Drawer,
  IconButton,
  useMediaQuery,
  Avatar,
} from "@material-ui/core";
import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import customTheme from "./CustomTheme.web";
import {
  Community,
  AppLogo,
  Home,
  About,
  notification,
  setting,
  network,
  message,
  profileMobileView,
} from "./assets.web";

interface HeaderProps {
  navigation: (path: string) => void;
  activeMenu: string;
  drawerOpen: boolean;
  handleMenuClick: (id: string) => void;
  toggleDrawer: () => void;
  isAuthenticated?: boolean;
  profile?: any;
}

const Header: React.FC<HeaderProps> = ({
  navigation,
  activeMenu,
  drawerOpen,
  handleMenuClick,
  toggleDrawer,
  isAuthenticated,
  profile,
}) => {
  const isMobile = useMediaQuery("(max-width:900px)");
  const Menu = isAuthenticated
    ? [
        {
          id: "1",
          name: "Home",
          icon: Home,
          path: "LandingPage",
        },
        {
          id: "2",
          name: "About Us",
          icon: About,
          path: "#",
        },
        {
          id: "3",
          name: "Network",
          icon: network,
          path: "FriendsList",
        },
        {
          id: "4",
          name: "Messages",
          icon: message,
          path: "Chat",
        },
        {
          id: "5",
          name: "Community Feed",
          icon: Community,
          path: "NewsFeed",
        },
        isMobile && {
          id: "6",
          name: "Notifications",
          icon: notification,
          path: "#",
        },
        isMobile && {
          id: "7",
          name: "Settings",
          icon: setting,
          path: "Settings",
        },
        isMobile && {
          id: "8",
          name: "Profile",
          icon: profileMobileView,
          path: "Customisableuserprofiles",
        },
      ]
    : [
        {
          id: "1",
          name: "Home",
          icon: Home,
          path: "LandingPage",
        },
        {
          id: "2",
          name: "About Us",
          icon: About,
          path: "#",
        },
        {
          id: "3",
          name: "Community Feed",
          icon: Community,
          path: "NewsFeed",
        },
      ];

  return (
    <Main>
      <LeftContainer>
        {isMobile && (
          <IconButton onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        )}
        <img src={AppLogo} alt="logo" />
      </LeftContainer>
      {!isMobile && (
        <>
          <Menus>
            {Menu.map((item: any) => (
              <MenuItem
                key={item.id}
                onClick={() => handleMenuClick(item.id)}
                data-test-id="handleMenuClick"
              >
                {activeMenu === item.id && (
                  <img
                    style={{ marginRight: "4px", height: 14, width: 15 }}
                    src={item.icon}
                    alt="icon"
                  />
                )}
                <Typography
                  style={{
                    fontWeight: activeMenu === item.id ? 700 : 400,
                    fontSize: "16px",
                    color:
                      activeMenu === item.id
                        ? customTheme.palette.black.primary
                        : customTheme.palette.grey.secondary,
                    fontFamily: "Open Sans",
                  }}
                  onClick={() => navigation(item.path)}
                >
                  {item.name}
                </Typography>
              </MenuItem>
            ))}
          </Menus>
          {isAuthenticated ? (
            <LoogedInSection>
              <ButtonAuth
                style={{
                  backgroundColor: customTheme.palette.grey.primary,
                  width: 105,
                }}
                variant="contained"
                onClick={() => navigation("ContentModeration")}
              >
                Moderate
              </ButtonAuth>
              <img
                style={{ cursor: "pointer" }}
                src={setting}
                alt="setting"
                onClick={() => navigation("Settings")}
              />
              <img
                style={{ cursor: "pointer" }}
                src={notification}
                alt="notification"
              />
              <Avatar
                style={{ cursor: "pointer" }}
                src={profile}
                onClick={() => navigation("Customisableuserprofiles")}
              />
            </LoogedInSection>
          ) : (
            <Buttons>
              <ButtonAuth variant="outlined" onClick={() => navigation("EmailAccountRegistrationWeb")}>
                Sign Up
              </ButtonAuth>
              <ButtonAuth
                style={{
                  backgroundColor: customTheme.palette.grey.primary,
                  width: 105,
                }}
                variant="contained"
                onClick={() => navigation("EmailAccountLoginBlock")}
              >
                Login
              </ButtonAuth>
            </Buttons>
          )}
        </>
      )}
      {isMobile && (
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
          <DrawerContent>
            {Menu.map((item: any) => (
              <MenuItem key={item.id} onClick={() => handleMenuClick(item.id)}>
                <img
                  style={{ marginRight: "4px" }}
                  src={item.icon}
                  alt="icon"
                />
                <Typography
                  style={{
                    fontWeight: activeMenu === item.id ? 700 : 400,
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    color:
                      activeMenu === item.id
                        ? customTheme.palette.black.primary
                        : customTheme.palette.grey.secondary,
                  }}
                  onClick={() => navigation(item.path)}
                >
                  {item.name}
                </Typography>
              </MenuItem>
            ))}
            {!isAuthenticated ? (
              <ButtonContainer>
                <ButtonAuth
                  variant="outlined"
                  onClick={() => navigation("EmailAccountRegistrationWeb")}
                >
                  Sign Up
                </ButtonAuth>
                <ButtonAuth
                  style={{
                    backgroundColor: customTheme.palette.grey.primary,
                    minWidth: 105,
                  }}
                  variant="contained"
                  onClick={() => navigation("EmailAccountLoginBlock")}
                >
                  Login
                </ButtonAuth>
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <ButtonAuth variant="outlined">Moderate</ButtonAuth>
              </ButtonContainer>
            )}
          </DrawerContent>
        </Drawer>
      )}
    </Main>
  );
};

const Main = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: customTheme.palette.white.main,
  height: "76px",
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  alignItems: "center",
  padding: "0px 40px",
  "@media (max-width:900px)": {
    padding: "0px 16px",
  },
});

const LeftContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "16px",
});

const Menus = styled(Box)({
  display: "flex",
  gap: "24px",
});

const MenuItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  gap: "4px",
});

const Buttons = styled(Box)({
  display: "flex",
  gap: "24px",
});

const DrawerContent = styled(Box)({
  width: "250px",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  marginTop: "20px",
});

const ButtonAuth = styled(Button)({
  fontSize: 16,
  fontWeight: 700,
  fontFamily: "Open Sans",
  textTransform: "none",
});

const LoogedInSection = styled("div")({
  display: "flex",
  gap: 24,
  alignItems: "center",
});

export default Header;
