import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  searchValue:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token:string,
  Blockeduser:any,
  isVisible:boolean,
  activeRecordId:number;
  activeId:number,
  activeFirstName:string,
  activeLastName:string,
  activeCreatedAt:string,
  activeUpdatedAt:string,
  userToBlock:string,
  value: any,
  BlockedUsers:any,
  totalCount:number,
  currentPage:number,
  usersPerPage:number,
  dataNotFound:string,
  openModal:boolean,
  userId: number | null;
  userName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BlockedusersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  value:any;
  editorState:any;
  BlockeduserApiCallId: any;
  deleteBlockeduserApiCallId:any;
  addBlockeduserApiCallId:any;
  getBlockedUserCallId:any;
  unBlockedUserCallId:any;
  richtext:any
  editor: any;
  onChange: (editorState: any) => void;
  setEditor: (editor: any) => void;
  focusEditor: () => void;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.editorState = null;//createEditorStateWithText("");

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token:"",
      Blockeduser:[],
      isVisible:false,
      activeRecordId:0,
      activeId:0,
      activeFirstName:"",
      activeLastName:"",
      activeCreatedAt:"",
      activeUpdatedAt:"",
      userToBlock:"",
      value: this.value,
      BlockedUsers:[],
      totalCount:1,
      currentPage:1,
      usersPerPage:8,
      dataNotFound:"",
      openModal:false,
      userId: null,
      userName: "",
      };
    this.onChange = (value) => {this.value=value; this.setState({value:value})};
    this.setEditor = (editor) => {
      this.editor = editor;
    };
    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    const { currentPage, usersPerPage } = this.state;
    const { searchValue } = this.props;

    this.getBlockedUsers(
      searchValue,
      currentPage,
      usersPerPage,
    );
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    const { searchValue } = this.props;
    const { currentPage, usersPerPage } = this.state;
    if (
      prevProps.searchValue !== searchValue ||
      prevState.currentPage !== currentPage ||
      prevState.usersPerPage !== usersPerPage
    ) {
      this.debouncedSearch(searchValue, currentPage, usersPerPage);
    }
  }

  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }
  // Customizable Area End
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({token:token})
      this.getBlockeduser(token)
    }
    else if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      this.blockAllAPIAassemblerCode(apiRequestCallId,responseJson)

      switch (apiRequestCallId) {
        case this.getBlockedUserCallId:
          this.getBlockedUserCallIdApi(responseJson);
          break;

        case this.unBlockedUserCallId:
          this.unBlockedUserCallIdApi(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  blockAllAPIAassemblerCode=(apiRequestCallId:any,responseJson:any)=>{
    if (responseJson && responseJson.data) {
        if(apiRequestCallId === this.BlockeduserApiCallId){
          this.setState({Blockeduser:responseJson.data})
        }
        if(apiRequestCallId === this.addBlockeduserApiCallId){
          this.props.navigation.goBack()
        }
      }
      else if(responseJson && !responseJson.data&&apiRequestCallId === this.deleteBlockeduserApiCallId){
        this.setState({isVisible:false})
        this.getBlockeduser(this.state.token)
      }
      else if(responseJson && responseJson.errors){
        this.setState({Blockeduser:[]})
        // this.parseApiErrorResponse(responseJson);
        // this.parseApiCatchErrorResponse(errorReponse);
      }

  }

  getBlockedUserCallIdApi = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        BlockedUsers: responseJson.data,
        currentPage: responseJson?.meta?.current_page,
        totalCount: responseJson?.meta?.total_pages,
      });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    } else if (responseJson.errors && responseJson.errors[0]?.message) {
      this.setState({
        dataNotFound: responseJson.errors[0]?.message,
        BlockedUsers: [],
      });
    }
  };

  unBlockedUserCallIdApi = (responseJson: any) => {
    if (responseJson.message) {
      const { currentPage, usersPerPage } = this.state;
      const { searchValue } = this.props;
      this.getBlockedUsers(
        searchValue,
        currentPage,
        usersPerPage,
      );
      this.handleUnBlockCancel()
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  setModal=(item:any)=>{
    this.setState({
      activeRecordId:item.id,
      activeId:item.attributes.account.id,
      activeFirstName:item.attributes.account.first_name,
      activeLastName:item.attributes.account.last_name,
      activeCreatedAt:item.attributes.account.created_at,
      activeUpdatedAt:item.attributes.account.updated_at,
      isVisible:!this.state.isVisible})
  }
  hideModal=()=>{
    this.setState({ isVisible: !this.state.isVisible });
  }

  addBlockeduser(){
    //@ts-ignore
    this.props.navigation.navigate("AddBlockeduser")
  }

  setTitle=(txt:string)=>{
    this.setState({userToBlock:txt})
  }

  deleteBlockeduser(id:number){
    this.deleteBlockeduserApiCall(configJSON.BlockeduserApiEndPoint+`/${id}`)
  }

  addBlockeduserCall = () => {
    if(this.state.userToBlock.trim()===""){
      this.showAlert(configJSON.configError,configJSON.configErrorTitle)
      return false;
    }
    else{
      var bodyData = {
        data : {
          attributes: {
            account_id: this.state.userToBlock
          }
        }
      }

      const header = {
        "Content-Type": configJSON.blockeduserApiApiContentType,
        "token":this.state.token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addBlockeduserApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.BlockeduserApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
      };
}

  deleteBlockeduserApiCall = (endPointURL:string,) => {
    const header = {
      "Content-Type": configJSON.blockeduserApiApiContentType,
      "token":this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteBlockeduserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getBlockeduser = (token:string) => {

    const header = {
      "Content-Type": configJSON.blockeduserApiApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.BlockeduserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.BlockeduserApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    request.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"? request.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body))
    : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPage: page });
  };

  getBlockedUsers = async (
    search?: string,
    currentPage?: number,
    perPage?: number
  ) => {
    this.getBlockedUserCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_block_users/block_users?search=${search}&page=${currentPage}&per_page=${perPage}`,
      contentType: "application/json",
    });
  };

  handleNavigation = async (path: string, receipientId?: number) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
    if (receipientId) {
      setStorageData("receipientId", receipientId);
    }
  };

  unBlockUser = async (userId: any) => {
    this.unBlockedUserCallId = await this.apiCall({
      method: "DELETE",
      endPoint: `bx_block_block_users/block_users/${userId}`,
      contentType: "application/json",
    });
  };

  debouncedSearch = this.debounce(this.getBlockedUsers, 500);

  debounce(func: (...args: any) => any, delay: number) {
    let timeout: NodeJS.Timeout | undefined;
    return function(...args: any) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  handleUnBlockCancel = () => {
    this.setState({ openModal: false });
  };

  openUnBlockModal = (userId: number, userName: string) => {
    this.setState({ openModal: true, userId, userName });
  };

  // Customizable Area End
}