Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NewsFeed";
exports.targetedFeedEndpoint = "bx_block_newsfeed/news_feeds";
exports.labelBodyText = "NewsFeed Body";
exports.btnExampleTitle = "CLICK ME";
exports.NewsFeedFlatList = 'NewsFeedFlatList'
// Customizable Area End