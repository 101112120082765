import React from "react";

// Customizable Area Start
import Header from "../../../components/src/CustomHeader.web";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  styled,
} from "@material-ui/core";
import {
  coverProfile,
  editIcon,
  eventDelete,
  profilePlaceholder,
  rightArrow,
  vector,
} from "./assets";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
import CustomMenu from "../../../components/src/CustomMenuItem.web";
import CustomModal from "../../../components/src/CustomModal.web";
import { setStorageData } from "../../../framework/src/Utilities";

interface Friend {
  attributes: {
    id: number;
    photo: string;
    name: string;
    mutual_friend: number;
    chat_url: string;
    receipient_id: number;
  };
}
// Customizable Area End

import CustomisableUserProfilesWebController, {
  Props,
} from "./CustomisableUserProfilesWebController";

export default class CustomisableUserProfiles extends CustomisableUserProfilesWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Header
          navigation={this.handleNavigation}
          activeMenu={this.state.activeMenu}
          drawerOpen={this.state.drawerOpen}
          handleMenuClick={this.handleMenuClick}
          toggleDrawer={this.toggleDrawer}
          isAuthenticated={true}
          profile={this.state.profile}
          data-test-id="handleNavigation"
        />
        {this.state.editProfile ? (
          <Main>
            <MainContainer>
              <CoverProfile
                profile={this.state.selectedProfileCover || coverProfile}
              >
                <Prifile
                  src={this.state.selectedProfile || profilePlaceholder}
                ></Prifile>
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.handleProfilePictureChange}
                  data-test-id="handleProfilePictureChange"
                  style={{ display: "none" }}
                  ref={this.fileInputRef1}
                />
                <label htmlFor="profile-picture-input">
                  <ProfileContainer>
                    <EditButton1
                      src={editIcon}
                      alt="editIcon"
                      onClick={this.handleClickProfile}
                      data-test-id="handleClickProfile"
                    ></EditButton1>
                  </ProfileContainer>
                </label>
              </CoverProfile>
              <input
                type="file"
                accept="image/*"
                onChange={this.handleCoverProfilePictureChange}
                style={{ display: "none" }}
                ref={this.fileInputRef}
                data-test-id="handleCoverProfilePictureChange"
              />
              <label htmlFor="profile-picture-input">
                <EditButton
                  src={editIcon}
                  onClick={this.handleClickCover}
                  data-test-id="handleClickCover"
                ></EditButton>
              </label>
              <CustomMenu
                open={this.state.openCover}
                onClose={this.handleCloseCover}
                anchorEl={this.state.anchorElCover}
                options={this.menuOptionsCover}
                position="right"
              />
              <div style={{ position: "relative", top: 80, left: 38 }}>
                {this.state.profileError && (
                  <ErrorMsg>{this.state.profileError}</ErrorMsg>
                )}
              </div>
              <MainSelection>
                <CustomMenu
                  open={this.state.open}
                  onClose={this.HandleCloseProfile}
                  data-test-id="HandleCloseProfile"
                  anchorEl={this.state.anchorEl}
                  options={this.menuOptions}
                  position="left"
                />
                <PrifileMain style={{ marginTop: 102, marginBottom: 30 }}>
                  <ProfileName>Basic Information</ProfileName>
                </PrifileMain>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Label>Name</Label>
                    <Input
                      type="text"
                      placeholder="name"
                      value={this.state.name}
                      onChange={this.handleNameChange}
                      data-test-id="handleNameChange"
                      isError={this.state.errorNameFeild}
                    />
                    {this.state.errorNameFeild && (
                      <ErrorMsg>{this.state.errorNameFeild}</ErrorMsg>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Label>Email</Label>
                    <Input type="email" value={this.state.email} disabled />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
                    <Label>Bio</Label>
                    <TextArea
                      placeholder="Tell about yourself, including interests, hobbies, and personal anecdotes......"
                      value={this.state.profileBio}
                      onChange={this.handleProfileBioChange}
                      data-test-id="handleProfileBioChange"
                    />
                  </Grid>
                </Grid>
                <div style={{ display: "flex", gap: 30, marginTop: 30 }}>
                  <ButtonOutline1
                    variant="outlined"
                    onClick={this.handleCancelProfile}
                    data-test-id="handleCancelProfile"
                  >
                    Cancel
                  </ButtonOutline1>
                  <ButtonContained
                    onClick={this.handleUpdateProfile}
                    variant="contained"
                    data-test-id="handleUpdateProfile"
                  >
                    Save
                  </ButtonContained>
                </div>
              </MainSelection>
            </MainContainer>
          </Main>
        ) : (
          <Main>
            <MainContainer>
              <CoverProfile
                profile={this.state.selectedProfileCover || coverProfile}
              >
                <Prifile
                  src={this.state.selectedProfile || profilePlaceholder}
                ></Prifile>
              </CoverProfile>
              <MainSelection>
                <PrifileMain style={{ marginTop: 102, marginBottom: 30 }}>
                  <ProfileName>{this.state.name}</ProfileName>
                  <img
                    style={{ cursor: "pointer" }}
                    src={editIcon}
                    alt="editIcon"
                    onClick={this.handleEditProfile}
                  />
                </PrifileMain>
                <ProfileDescription>{this.state.email}</ProfileDescription>
                <ProfileDescription>{this.state.profileBio}</ProfileDescription>
                <Divider />
                <PrifileMain>
                  <ProfileName>Your Friends</ProfileName>
                  <ButtonOutline
                    variant="outlined"
                    onClick={() => {
                      this.handleNavigation("FriendsList");
                    }}
                  >
                    View all
                  </ButtonOutline>
                </PrifileMain>
                <div style={{ marginTop: 30 }}>
                  <ProfileDescription>Recently added</ProfileDescription>
                </div>
                {this.state.recentlyAddedFriend?.map(
                  (object: Friend, index) => (
                    <div style={{ marginTop: 16 }}>
                      <PrifileMain style={{ padding: "12px 0px" }} key={index}>
                        <FriendProfileMain
                          data-test-id="navigateToFriendsProfile"
                          onClick={() => {
                            this.handleAddListNavigation(
                              "FriendsProfile",
                              undefined,
                              object.attributes.receipient_id
                            );
                            setStorageData("activeChatUrl", object.attributes.chat_url);
                            setStorageData("activeChatName", object.attributes.name);
                            setStorageData("activeChatPhoto", object.attributes.photo);
                            setStorageData("activeChatId", object.attributes.receipient_id);
                          }}>
                          <FriendProfileImg src={object.attributes.photo} />
                          <FriendInner>
                            <FriendProfileName>
                              {object.attributes.name}
                            </FriendProfileName>
                            <FriendProfileMutual>
                              {object.attributes.mutual_friend}
                            </FriendProfileMutual>
                          </FriendInner>
                        </FriendProfileMain>
                        <ButtonContained
                          variant="outlined"
                          data-test-id="navigateToChatButton"
                          onClick={() => {
                            this.handleAddListNavigation("Chat", object.attributes.id);
                            setStorageData("activeChatUrl", object.attributes.chat_url);
                            setStorageData("activeChatName", object.attributes.name);
                            setStorageData("activeChatPhoto", object.attributes.photo);
                            setStorageData("activeChatId", object.attributes.receipient_id);
                          }}
                        >
                          Message
                        </ButtonContained>
                      </PrifileMain>
                      <DividerItem />
                    </div>
                  )
                )}
                <div style={{ marginTop: 60 }}>
                  <PrifileMain>
                    <BlockedHeading>Blocked Users</BlockedHeading>
                    <ButtonOutline
                      variant="outlined"
                      onClick={() => {
                        this.handleNavigation("FriendsList", true);
                      }}
                    >
                      View all
                    </ButtonOutline>
                  </PrifileMain>
                  {this.state.blockedUser?.map((object: any, index: any) => (
                    <div style={{ marginTop: 16 }}>
                      <PrifileMain style={{ padding: "12px 0px" }} key={index}>
                        <FriendProfileMain
                          data-test-id="navigateToFriendsProfile"
                          onClick={() => {
                            this.handleAddListNavigation(
                              "FriendsProfile",
                              undefined,
                              object.attributes.account_id
                            );
                          }}
                        >
                          <FriendProfileImg
                            src={object.attributes.profile_image}
                          />
                          <FriendInner>
                            <FriendProfileName>
                              {object.attributes.name}
                            </FriendProfileName>
                          </FriendInner>
                        </FriendProfileMain>
                        <ButtonContained
                          variant="outlined"
                          data-test-id="navigateToChatButton"
                          onClick={() =>
                            this.openUnBlockModal(
                              object.id,
                              object.attributes.name
                            )
                          }
                        >
                          Unblock
                        </ButtonContained>
                      </PrifileMain>
                      <DividerItem />
                    </div>
                  ))}
                  <CustomModal
                    open={this.state.openModal}
                    cancelButtonText="Cancel"
                    data-test-id="handleUnBlockCancel"
                    doneButtonText="Unblock"
                    onCancel={this.handleUnBlockCancel}
                    onDone={() => this.unBlockUser(this.state.userId)}
                    title="Unblock Account"
                    subtitle={
                      <>
                        Are you sure you want to Unblock{" "}
                        <span style={{ fontWeight: 700 }}>
                          {this.state.userName}
                        </span>
                        ?
                      </>
                    }
                  ></CustomModal>
                </div>
                <Divider />
                <PrifileMain>
                  <ProfileName>Lists</ProfileName>
                  <ButtonOutline
                    variant="outlined"
                    onClick={this.handleOpenCreateList}
                    data-test-id="handleOpenCreateList"
                  >
                    <img src={vector} style={{ paddingRight: 8 }} />
                    Create new list
                  </ButtonOutline>
                  <CustomModal
                    open={this.state.openCreateList}
                    data-test-id="handleCreateListCancel"
                    cancelButtonText="Cancel"
                    doneButtonText="Confirm"
                    onCancel={this.handleCreateListCancel}
                    onDone={this.handleCreateListDone}
                    title="Create List"
                  >
                    <div style={{ margin: "40px 0px" }}>
                      <Label>Title</Label>
                      <Input
                        type="text"
                        placeholder="Title"
                        value={this.state.title}
                        onChange={this.handleTitleChange}
                        data-test-id="handleTitleChange"
                        isError={this.state.titleError}
                      />
                      {this.state.titleError && (
                        <ErrorMsg>{this.state.titleError}</ErrorMsg>
                      )}
                    </div>
                    <div style={{ margin: "40px 0px" }}>
                      <Label>Description</Label>
                      <TextArea
                        type="text"
                        placeholder="Description..."
                        value={this.state.description}
                        onChange={this.handleDescriptionChange}
                        data-test-id="handleDescriptionChange"
                        isError={this.state.descriptionError}
                      />
                      <WordCount descriptionError={this.state.descriptionError}>
                        {this.state.descriptionError && (
                          <ErrorMsg>{this.state.descriptionError}</ErrorMsg>
                        )}
                        {this.state.descriptionWordCount}/100
                      </WordCount>
                    </div>
                  </CustomModal>
                </PrifileMain>
                {this.state.listData?.map((profile: any, index) => (
                  <>
                    <PrifileMain
                      key={index}
                      style={{ marginTop: 30, cursor: "pointer" }}
                      data-test-id="handleAddListNavigation"
                      onClick={() =>
                        this.handleAddListNavigation("AddressList", profile.id)
                      }
                    >
                      <FriendProfileName>
                        {profile.attributes.title}
                      </FriendProfileName>
                      <img src={rightArrow} alt="arrowIcon" />
                    </PrifileMain>
                    <Divider1 />
                  </>
                ))}
                <EventHeading>Events</EventHeading>
                {this.state.eventsList?.map((item: any, index: any) => (
                  <div key={index}>
                    <EventComp>
                      <Title>{item.attributes.event_name}</Title>
                      <Img
                        src={eventDelete}
                        alt="deleteButton"
                        data-test-id="handleOpenEventDeleteModal"
                        onClick={() => this.handleOpenEventDeleteModal(item.id)}
                      />
                    </EventComp>
                    <Description>{item.attributes.hashtags}</Description>
                    <Description>{item.attributes.description}</Description>
                    <ButtonContainedEditEvent>Edit</ButtonContainedEditEvent>
                  </div>
                ))}
                {this.state.openEventDeleteModal && (
                  <ParentModal>
                    <ChildModal>
                      <EventTitleMain>Delete</EventTitleMain>
                      <SubTitle>
                        You are about to delete your post. Are you sure you want
                        to delete your event?
                      </SubTitle>
                      <div style={webStyle.buttonContainer}>
                        <ButtonCancel
                          variant="outlined"
                          onClick={() => this.handleOpenEventDeleteModal()}
                          style={{ marginRight: "1rem" }}
                        >
                          Cancel
                        </ButtonCancel>
                        <ButtonDone
                          variant="contained"
                          data-test-id="eventDelete"
                          onClick={this.eventDelete}
                        >
                          Delete
                        </ButtonDone>
                      </div>
                    </ChildModal>
                  </ParentModal>
                )}
              </MainSelection>
            </MainContainer>
          </Main>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 40,
  },
};
const Main = styled("div")({
  padding: "40px 24px 174px 40px",
});
const MainContainer = styled("div")({
  borderRadius: 24,
  boxShadow: `
  0px 8px 32px 0px #0000000F,
  0px 4px 8px 0px #00000008,
  0px 25px 50px 0px #00000017
`,
});

const CoverProfile = styled("div")((profile: any) => ({
  backgroundImage: `url(${profile.profile})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  minHeight: "217",
  borderRadius: "24px 24px 0px 0px",
  position: "relative",
}));

const EditButton = styled("img")({
  position: "absolute",
  top: "135px",
  right: "42px",
  cursor: "pointer",
});

const Prifile = styled("img")({
  width: 152,
  height: 152,
  borderRadius: "50%",
  border: `4px solid ${customTheme.palette.white.main}`,
  position: "absolute",
  bottom: "-78px",
  left: 33,
  backgroundSize: "cover",
  backgroundPosition: "center",
});

const ProfileContainer = styled("div")({
  position: "relative",
});

const EditButton1 = styled("img")({
  position: "absolute",
  top: "135px",
  left: "150px",
  cursor: "pointer",
});

const MainSelection = styled("div")({
  paddingInline: 36,
  paddingBottom: 40,
});

const PrifileMain = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ProfileName = styled(Typography)({
  ...CustomFontVariant.palette.font30700,
});

const BlockedHeading = styled(Typography)({
  ...CustomFontVariant.palette.font30700,
  marginBottom: 30,
});

const EventHeading = styled(Typography)({
  ...CustomFontVariant.palette.font30700,
  margin: "60px 0px 40px 0px",
});

const ProfileDescription = styled("div")({
  ...CustomFontVariant.palette.font16400,
  marginBottom: 16,
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  margin: "60px 0px",
});

const Divider1 = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
});

const DividerItem = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.super}`,
});

const ButtonOutline = styled(Button)({
  padding: "10px 60px",
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  borderColor: customTheme.palette.black.primary,
});

const ButtonOutline1 = styled(Button)({
  padding: "10px 40px",
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  borderColor: customTheme.palette.black.primary,
});

const ButtonContained = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
});

const FriendProfileMain = styled("div")({
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
});

const FriendProfileName = styled("div")({
  ...CustomFontVariant.palette.font20400,
});

const FriendProfileMutual = styled("div")({
  ...CustomFontVariant.palette.font14700,
  color: customTheme.palette.grey.main,
});

const FriendProfileImg = styled(Avatar)({
  width: 56,
  height: 56,
  borderRadius: "50%",
});

const FriendInner = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  paddingLeft: 10,
});

const Label = styled("label")({
  ...CustomFontVariant.palette.font14700,
  color: customTheme.palette.grey.secondary,
});

const Input = styled("input")(({ isError }: any) => ({
  marginTop: 8,
  height: 56,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const TextArea = styled("textarea")(({ isError }: any) => ({
  marginTop: 8,
  height: 121,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const ErrorMsg = styled(Typography)({
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.red.primary,
  marginTop: 2,
});

const WordCount = styled("div")(({ descriptionError }: any) => ({
  display: "flex",
  justifyContent: descriptionError ? "space-between" : "end",
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.grey.secondary,
}));

const EventComp = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const Title = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font20700,
});

const Description = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font18400,
  margin: "16px 0px",
});

const Img = styled("img")({
  cursor: "pointer",
});

const ButtonContainedEditEvent = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
  margin: "12px 0px 60px 0px",
});

const ParentModal = styled(Box)({
  position: "fixed",
  background: "#00000080",
  top: 0,
  bottom: 50,
  left: 0,
  right: 0,
  width: "100%",
  height: "100%",
  zIndex: 999,
  overflow: "auto",
});

const ChildModal = styled(Box)({
  width: "100%",
  maxWidth: 820,
  zIndex: 99999,
  background: customTheme.palette.white.main,
  padding: "36px",
  borderRadius: "20px",
  margin: "150px auto",
  position: "relative",
});

const ButtonDone = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  backgroundColor: `${customTheme.palette.grey.primary}!important`,
  color: `${customTheme.palette.black.primary}!important`,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none !important",
});

const ButtonCancel = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  color: `${customTheme.palette.black.primary}!important`,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none !important",
  borderColor: `${customTheme.palette.black.primary}!important`,
});

const EventTitleMain = styled("div")({
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font20700,
});

const SubTitle = styled(Typography)({
  marginTop: 36,
  ...CustomFontVariant.palette.font24400,
  color: customTheme.palette.grey.secondary,
});

// Customizable Area End
