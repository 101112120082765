// Customizable Area Start
export const imagIcon = require("../assets/gallery.png");
export const Vector = require("../assets/Vector.png");
export const HomeImage = require("../assets/image_.png");
export const CloseIcon = require("../assets/close.png");
export const LeftArrow = require("../assets/leftArrow.png");
export const RightArrow = require("../assets/rightArrow.png");
export const ThumbUp = require("../assets/bookmark_thumb_up.png");
export const ChatBubble = require("../assets/chat_bubble.png");
export const Reply = require("../assets/reply.png");
export const Flag = require("../assets/outlined_flag.png");

// Customizable Area End
