import React from "react";

import {
  Container,
  Button,
  Avatar,
  // Customizable Area Start
  // Customizable Area End
  Switch,
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material";

// Customizable Area Start
import CustomFontVariant from "../../../components/src/CustomFontVariant.web"
import { FormControlLabel, Radio, RadioGroup, styled, RadioProps } from "@material-ui/core";
import customTheme from "../../../components/src/CustomTheme.web";
import Toast from "../../../components/src/CustomSnackbar.web";
// Customizable Area End

import PrivacySettingsController, {
  Props,
  configJSON,
} from "./PrivacySettingsController";
import "@mui/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class PrivacySettings extends PrivacySettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  BpRadio = (props: RadioProps) => {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <MainContainer>
        <Heading>Privacy Settings</Heading>
        <Divider />
        <Label>Set my account public or private</Label>
        {<RadioGroup
          data-test-id="radio-btn"
          defaultValue={this.state.getPrivacyKey}
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({getPrivacyKey: event.target.value})
            this.props.updatePrivacyKey(event.target.value)}
          }
        >
        <RadioLabel style={{...CustomFontVariant.palette.font18400,}} value="is_public" control={<this.BpRadio />} label="Public" />
        <RadioLabel style={{...CustomFontVariant.palette.font18400,}} value="is_private" control={<this.BpRadio />} label="Private" />
        </RadioGroup>}
        <ButtonSave data-test-id="save-btn" onClick={this.updatePrivacyData}>
          Save
        </ButtonSave>
        <Toast
          open={this.state.showNotification}
          message={this.state.updateMessage}
          severity={"success"}
          onClose={this.handleNotification}
          duration={3000}
          position={{ vertical: "top", horizontal: "center" }}
          data-test-id="close-toaster"
        />
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled("div")({});

const Heading = styled("div")({
  ...CustomFontVariant.palette.font24700,
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  margin: "30px 0px 25px",
});

const Label = styled("div")({
  ...CustomFontVariant.palette.font18400,
  color: customTheme.palette.grey.bold,
  marginBottom: "15px"
});

const BpIcon = styled('span')({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },

});

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#000000',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#000000',
  },
});

const RadioLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    ...CustomFontVariant.palette.font18400,
    color: customTheme.palette.grey.bold,
  }
});

const ButtonSave = styled('button')({
    ...CustomFontVariant.palette.font14700,
  textTransform: "none !important",
  backgroundColor: customTheme.palette.grey.primary,
  minWidth: 143,
  border: 'none',
  borderRadius: '4px',
  padding: '10px 8px',
  marginTop: '20px'
});
// Customizable Area End
