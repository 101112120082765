import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import React from "react";
interface ApiCallData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {};
  type?: string;
}

interface ListItem {
  id: string;
  type: string;
  attributes: {
    title: string;
    default_created: boolean;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  lat: number;
  lng: number;
  address: string;
  openMainModal: boolean;
  selectedTab: number;
  openAddModal: boolean;
  checked: boolean;
  selectedDate: any;
  shareModalOpen: boolean;
  openMenu: boolean;
  anchorEl: HTMLElement | null;
  startDate: Date | null;
  endDate: Date | null;
  startDateError: string;
  endDateError: string;
  commonDateError: string;
  disabledEndCalender: boolean;
  allLocationAccountListing: [];
  locationId: string;
  addListData: ListItem[];
  streetViewImgUrl: string;
  pitch: number;
  heading: number;
  zoom: number;
  toastOpen: boolean;
  toastMessage: string;
  mapKey: string;
  viewCount: number | null;
  favouriteList: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfargooglestreetviewinlaycontentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  panoramaRef = React.createRef<any>();
  createLocationCallId: string = "";
  createLocationAccountCallId: string = "";
  getAddListCallId: string = "";
  addToLocationListCallId: string = "";
  getMapApiKeyCallId: string = "";
  timeoutId: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      lat: 0,
      lng: 0,
      address: "",
      openMainModal: false,
      selectedTab: 0,
      openAddModal: false,
      checked: false,
      selectedDate: "",
      shareModalOpen: false,
      openMenu: false,
      anchorEl: null,
      startDate: null,
      endDate: null,
      startDateError: "",
      endDateError: "",
      commonDateError: "",
      disabledEndCalender: false,
      allLocationAccountListing: [],
      locationId: "",
      addListData: [],
      streetViewImgUrl: "",
      pitch: 0,
      heading: 0,
      zoom: 0,
      toastOpen: false,
      toastMessage: "",
      mapKey: "",
      viewCount: null,
      favouriteList: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.createLocationCallId:
          this.createLocationCallIdApi(responseJson);
          break;

        case this.createLocationAccountCallId:
          this.createLocationAccountCallIdApi(responseJson);
          break;

        case this.getAddListCallId:
          this.getAddListCallIdApi(responseJson);
          break;

        case this.addToLocationListCallId:
          this.addToLocationListCallIdApi(responseJson);
          break;

        case this.getMapApiKeyCallId:
          this.getMapApiKeyCallIdApi(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  createLocationCallIdApi = (responseJson: any) => {
    const data = Array.isArray(responseJson.data)
      ? responseJson.data[0]
      : responseJson.data;

    if (data && data.id) {
      const {
        id: locationId,
        attributes: {
          account_location: accountLocation,
          view_count,
          favourite_list,
          heading,
          pitch,
          zoom,
        },
      } = data;

      this.setState({
        allLocationAccountListing: accountLocation,
        locationId,
        viewCount: view_count,
        favouriteList: favourite_list,
        heading,
        pitch,
        zoom,
      });
      setStorageData("locationId", locationId);
    }
  };

  createLocationAccountCallIdApi = (responseJson: any) => {
    if (!responseJson.errors) {
      this.handleAddCancelModal();
    } else {
      let commonDateError = "";
      let startDateError = "";
      let endDateError = "";

      responseJson.errors.forEach((error: any) => {
        if (error.message) {
          commonDateError = error.message;
        }
        if (error.starting_date) {
          startDateError = error.starting_date;
        }
        if (error.ending_date) {
          endDateError = error.ending_date;
        }
      });

      this.setState({
        commonDateError,
        startDateError,
        endDateError,
      });
    }
  };

  getAddListCallIdApi = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ addListData: responseJson.data }, () =>
        this.menuOptions()
      );
    }
  };

  addToLocationListCallIdApi = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ openMenu: false });
      this.createLocationHandler();
    }
  };

  getMapApiKeyCallIdApi = (responseJson: any) => {
    if (responseJson.map_key) {
      this.setState({ mapKey: responseJson.map_key });
    }
  };

  apiCall = async (data: ApiCallData) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  markers = [{ id: 1, position: { lat: 0, lng: 0 } }];

  async componentDidMount() {
    await this.getMapApiKey();
    await this.getLngLat();
    this.updateMarkers();
    this.getAddListingData();
    this.createLocationHandler();
  }

  async componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  updateMarkers = () => {
    const { lat, lng } = this.state;
    this.markers = [{ id: 1, position: { lat, lng } }];
  };

  handleMainModal = async (view?: boolean, pov?: boolean) => {
    this.setState({ openMainModal: !this.state.openMainModal });
    this.createLocationHandler(view, pov);
    this.getMapCurrentImage();
  };

  getLngLat = async () => {
    const url = new URL(window.location.href);
    const latParam = url.searchParams.get("lat");
    const lngParam = url.searchParams.get("lng");
    const addressParam = url.searchParams.get("address");
    const guestBookKeyParam = url.searchParams.get("action");
    if (latParam !== null && lngParam !== null && addressParam !== null) {
      this.setState({
        lat: parseFloat(latParam),
        lng: parseFloat(lngParam),
        address: addressParam,
      });
      setTimeout(() => {
        this.handleMainModal(true);
      }, 1500);
    } else {
      const lat = await getStorageData("lat");
      const lan = await getStorageData("lng");
      const address = await getStorageData("address");
      this.setState({ lat: lat * 1, lng: lan * 1, address });
    }
    if (guestBookKeyParam !== null) {
      this.setState({ selectedTab: parseFloat(guestBookKeyParam) });
    }
  };

  debounce(func: (...args: any[]) => any, delay: number) {
    let timeout: NodeJS.Timeout | undefined;

    return (...args: any[]) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  handlePositionChanged = (panorama: any) => {
    const newPosition = panorama.getPosition();
    const lat = newPosition.lat();
    const lng = newPosition.lng();

    const pov = panorama.getPov();
    const pitch = pov.pitch;
    const heading = pov.heading;
    const zoom = panorama.getZoom();

    if (lat !== this.state.lat || lng !== this.state.lng) {
      this.setState({ lat, lng });
    }

    this.debouncedSetState({ pitch, heading, zoom });
  };

  debouncedSetState = this.debounce((newState: any) => {
    if (
      newState.pitch !== this.state.pitch ||
      newState.heading !== this.state.heading ||
      newState.zoom !== this.state.zoom
    ) {
      this.setState(newState);
    }
  }, 500);

  handlePanoramaLoad = (panorama: google.maps.StreetViewPanorama) => {
    panorama.addListener("position_changed", () =>
      this.handlePositionChanged(panorama)
    );
    panorama.addListener("pov_changed", () =>
      this.handlePositionChanged(panorama)
    );
    panorama.addListener("zoom_changed", () =>
      this.handlePositionChanged(panorama)
    );
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  menuOptions = () => {
    return this.state.addListData.map((item) => ({
      label: item.attributes.title,
      action: () => {
        this.addToLocationList(item.id);
      },
    }));
  };

  handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget,
      openMenu: true,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null,
      openMenu: false,
    });
  };

  handleCheckBox = () => {
    this.setState({
      checked: !this.state.checked,
      disabledEndCalender: !this.state.disabledEndCalender,
      endDate: null,
      endDateError: "",
    });
  };

  validateDates = () => {
    const { startDate, endDate, checked } = this.state;
    let startDateError = "";
    let endDateError = "";

    if (!startDate) {
      startDateError = "Start date is required";
    }

    if (!checked) {
      if (!endDate) {
        endDateError = "End date is required";
      } else if (startDate && endDate < startDate) {
        endDateError = "End date cannot be before start date";
      }
    }

    this.setState({ startDateError, endDateError });
    return !startDateError && !endDateError;
  };

  handleAddModal = () => {
    if (this.state.openAddModal && !this.validateDates()) {
      return;
    }
    this.createLocationAccountHandler();
  };

  handleAddCancelModal = () => {
    this.setState({
      openAddModal: !this.state.openAddModal,
      startDate: null,
      endDate: null,
      endDateError: "",
      startDateError: "",
      checked: false,
      disabledEndCalender: false,
      commonDateError: "",
    });
    this.createLocationHandler();
  };

  handleStartDateChange = (date: any) => {
    this.setState({ startDate: date, startDateError: "", commonDateError: "" });
  };

  handleEndDateChange = (date: any) => {
    this.setState({ endDate: date, endDateError: "", commonDateError: "" });
  };

  handleShareModal = () => {
    this.setState({ shareModalOpen: !this.state.shareModalOpen });
  };

  createLocationHandler = async (
    view: boolean = false,
    pov: boolean = false
  ) => {
    const { heading, pitch, zoom } = this.state;
    const localStorageLat = await getStorageData("lat");
    const localStorageLng = await getStorageData("lng");
    const localStorageAddress = await getStorageData("address");
    const body = {
      location: {
        latitude: localStorageLat,
        longitude: localStorageLng,
        address: localStorageAddress,
        pitch: pitch,
        heading: heading,
        zoom: zoom,
        view,
        pov,
      },
    };

    this.createLocationCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_location/locations",
      contentType: "application/json",
      body,
    });
  };

  createLocationAccountHandler = async () => {
    const body = {
      account_location: {
        location_id: this.state.locationId,
        starting_date: this.state.startDate,
        ...(this.state.endDate
          ? { ending_date: this.state.endDate }
          : { currently_living: true }),
      },
    };

    this.createLocationAccountCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_location/account_locations",
      contentType: "application/json",
      body,
    });
  };

  getAddListingData = async () => {
    this.getAddListCallId = await this.apiCall({
      method: "GET",
      endPoint: "account_block/lists",
      contentType: "application/json",
    });
  };

  getMapCurrentImage = () => {
    const streetViewImgUrl = `https://maps.googleapis.com/maps/api/streetview?location=${
      this.state.lat
    },${this.state.lng}&heading=${this.state.heading}&pitch=${
      this.state.pitch
    }&fov=${180}&size=752x140&key=${this.state.mapKey}&height=144`;
    this.setState({ streetViewImgUrl });
  };

  addToLocationList = async (listID: string) => {
    const body = {
      data: {
        list_id: listID,
        location_id: this.state.locationId,
      },
    };
    this.addToLocationListCallId = await this.apiCall({
      method: "POST",
      endPoint: "account_block/list_items",
      contentType: "application/json",
      body,
    });
  };

  handleCloseToast = () => {
    this.setState({ toastOpen: false });
  };

  getMapApiKey = async () => {
    this.getMapApiKeyCallId = await this.apiCall({
      method: "GET",
      endPoint: "bx_block_location/locations/get_map_key",
      contentType: "application/json",
    });
  };

  handleNavigation = async (path: string, accountId?: number) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
    if (accountId) {
      setStorageData("receipientId", accountId);
    }
  };

  // Customizable Area End
}
