import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import {
  getStorageData,
  removeStorageData,
  setStorageData,
} from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  drawerOpen: boolean;
  activeMenu: string;
  editProfile: boolean;
  name: string;
  email: string;
  profileBio: string;
  anchorEl: any;
  open: boolean;
  editCover: boolean;
  openCover: boolean;
  anchorElCover: any;
  selectedProfileCover: any;
  selectedProfile: any;
  openCreateList: boolean;
  title: string;
  description: string;
  maxWords: number;
  listData: [];
  errorNameFeild: string;
  selectedProfileFile: any;
  selectedProfileCoverFile: any;
  titleError: string;
  descriptionError: string;
  touched: {
    name: boolean;
    profileBio: boolean;
    profileImage: boolean;
    profileCover: boolean;
  };
  profile: "";
  descriptionWordCount: number;
  isDescriptionLimitExceeded: boolean;
  profileError: string;
  removeProfile: any;
  removeCover: any;
  orignal: {
    name: string;
    bio: string;
    profilePicture: string;
    profileCover: string;
  };
  recentlyAddedFriend: [];
  blockedUser: [];
  openModal: boolean;
  userId: number | null;
  userName: string;
  openEventModal: boolean;
  eventId: number | null;
  openEventDeleteModal: boolean;
  eventsList: [];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CustomisableUserProfilesWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  profileUpdateCallId: string = "";
  createListCallId: string = "";
  getListCallId: string = "";
  fileInputRef: any;
  fileInputRef1: any;
  removeProfileCallId: string = "";
  removeProfileCoverCallId: string = "";
  unBlockedUserCallId: string = "";
  getEventListCallId: string = "";
  eventDeleteCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      drawerOpen: false,
      activeMenu: "",
      editProfile: false,
      name: "",
      email: "",
      profileBio: "",
      anchorEl: null,
      open: false,
      editCover: false,
      anchorElCover: null,
      openCover: false,
      selectedProfileCover: null,
      selectedProfile: null,
      openCreateList: false,
      title: "",
      description: "",
      maxWords: 50,
      listData: [],
      errorNameFeild: "",
      selectedProfileFile: "",
      selectedProfileCoverFile: "",
      titleError: "",
      descriptionError: "",
      touched: {
        name: false,
        profileBio: false,
        profileImage: false,
        profileCover: false,
      },
      profile: "",
      descriptionWordCount: 0,
      isDescriptionLimitExceeded: false,
      profileError: "",
      removeProfile: false,
      removeCover: false,
      orignal: {
        name: "",
        bio: "",
        profilePicture: "",
        profileCover: "",
      },
      recentlyAddedFriend: [],
      blockedUser: [],
      openModal: false,
      userId: null,
      userName: "",
      openEventModal: false,
      eventId: null,
      openEventDeleteModal: false,
      eventsList: [],
      // Customizable Area End
    };

    // Customizable Area Start
    this.fileInputRef = React.createRef();
    this.fileInputRef1 = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getListCallId:
          this.getListCallIdApi(responseJson);
          break;

        case this.profileUpdateCallId:
          this.profileUpdateCallIdApi(responseJson);
          break;

        case this.createListCallId:
          this.createListCallIdApi(responseJson);
          break;

        case this.unBlockedUserCallId:
          this.unBlockedUserCallIdApi(responseJson);
          break;

        case this.getEventListCallId:
          this.getEventListCallIdApi(responseJson);
          break;

        case this.eventDeleteCallId:
          this.eventDeleteCallIdApi(responseJson);
          break;

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getEventListCallIdApi = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ eventsList: responseJson.data });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  eventDeleteCallIdApi = (responseJson: any) => {
    if (responseJson.message) {
      this.handleGetEventList();
      this.setState({ openEventDeleteModal: false });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  getListCallIdApi = async (responseJson: any) => {
    if (responseJson.data) {
      const {
        name,
        email,
        bio,
        profile_image,
        profile_cover,
        connection,
        block_users,
      } = responseJson.data.attributes;
      const profile = await getStorageData("profile");
      await setStorageData("profileId", responseJson.data.id);
      await setStorageData("profileName", name);
      this.setState({
        listData: responseJson.data.attributes.list.data,
        name,
        email,
        profileBio: bio,
        selectedProfile: profile_image,
        selectedProfileCover: profile_cover,
        selectedProfileFile: profile_image,
        selectedProfileCoverFile: profile_cover,
        orignal: {
          name,
          bio,
          profilePicture: profile_image,
          profileCover: profile_cover,
        },
        touched: {
          name: false,
          profileBio: false,
          profileImage: false,
          profileCover: false,
        },
        profile,
        recentlyAddedFriend: connection.data,
        blockedUser: block_users,
      });
    } else if (responseJson.errors[0].token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  profileUpdateCallIdApi = async (responseJson: any) => {
    if (responseJson.data) {
      const {
        name,
        email,
        bio,
        profile_image,
        profile_cover,
      } = responseJson.data.attributes;
      await setStorageData("profile", profile_image);
      if (profile_image == null) await removeStorageData("profile");
      this.setState({
        name,
        email,
        profileBio: bio,
        selectedProfile: profile_image,
        selectedProfileCover: profile_cover,
        selectedProfileFile: profile_image,
        selectedProfileCoverFile: profile_cover,
        touched: {
          name: false,
          profileBio: false,
          profileImage: false,
          profileCover: false,
        },
        orignal: {
          name,
          bio,
          profilePicture: profile_image,
          profileCover: profile_cover,
        },
        removeProfile: false,
        removeCover: false,
        editProfile: false,
        errorNameFeild: "",
        profileError: "",
        profile: profile_image,
      });
    } else {
      if (responseJson.errors[0].first_name) {
        this.setState({
          errorNameFeild: responseJson.errors[0].first_name,
        });
      }
    }
  };

  createListCallIdApi = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        title: "",
        description: "",
        openCreateList: false,
        titleError: "",
        descriptionError: "",
      });
      this.handleGetList();
    }
  };

  unBlockedUserCallIdApi = (responseJson: any) => {
    if (responseJson.message) {
      this.handleGetList();
      this.handleUnBlockCancel();
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  async componentDidMount() {
    this.redirectInvalidAccount();
    this.handleGetList();
    this.handleGetEventList();
  }

  redirectInvalidAccount = async () => {
    const authToken = await getStorageData("token");
    const profile = await getStorageData("profile");
    this.setState({ profile });
    if (!authToken) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleNavigation = async (path: any, tabValue?: boolean) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
    await setStorageData("blockedTab", tabValue);
  };

  handleMenuClick = (id: any) => {
    this.setState({ activeMenu: id });
  };

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  checkEmptyFields = () => {
    const { name } = this.state;
    let isValid = true;
    if (!name && name !== null) {
      this.setState({
        errorNameFeild: "Name is required",
      });
      isValid = false;
    }
    return isValid;
  };

  checkCreateListEmptyFields = () => {
    const { title, description } = this.state;
    let isValid = true;

    if (!title) {
      this.setState({ titleError: "Title is required" });
      isValid = false;
    }

    if (!description) {
      this.setState({ descriptionError: "Description is required" });
      isValid = false;
    }

    return isValid;
  };

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: event.target.value,
      errorNameFeild: "",
      touched: { ...this.state.touched, name: true },
    });
  };

  handleProfileBioChange = (event: any) => {
    this.setState({
      profileBio: event.target.value,
      touched: { ...this.state.touched, profileBio: true },
    });
  };

  handleEditProfile = () => {
    this.handleGetList();
    this.setState({
      editProfile: !this.state.editProfile,
      errorNameFeild: "",
      profileError: "",
    });
  };

  handleCancelProfile = () => {
    const { name, bio, profilePicture, profileCover } = this.state.orignal;
    this.setState({
      name,
      profileBio: bio,
      editProfile: !this.state.editProfile,
      errorNameFeild: "",
      profileError: "",
      selectedProfile: profilePicture,
      selectedProfileCover: profileCover,
      selectedProfileFile: profilePicture,
      selectedProfileCoverFile: profileCover,
      removeCover: false,
      removeProfile: false,
    });
  };

  handleClickProfile = (event: any) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  HandleCloseProfile = () => {
    this.setState({ anchorEl: null, open: false });
  };

  handlePhotoLibrary = () => {
    this.handleEditProfilePicture();
    this.HandleCloseProfile();
  };

  handleRemovePhoto = () => {
    this.handleRemoveProfile();
    this.HandleCloseProfile();
  };

  handleClickCover = (event: any) => {
    this.setState({ anchorElCover: event.currentTarget, openCover: true });
  };

  handleCloseCover = () => {
    this.setState({ anchorElCover: null, openCover: false });
  };

  handlePhotoLibraryCover = () => {
    this.handleEditCoverProfilePicture();
    this.handleCloseCover();
  };

  handleCoverRemovePhoto = () => {
    this.handleRemoveProfileCover();
    this.handleCloseCover();
  };

  menuOptions = [
    { label: "Photo Library", action: this.handlePhotoLibrary },
    { label: "Remove photo", action: this.handleRemovePhoto },
    { label: "Cancel", action: this.HandleCloseProfile },
  ];

  menuOptionsCover = [
    { label: "Photo Library", action: this.handlePhotoLibraryCover },
    { label: "Remove photo", action: this.handleCoverRemovePhoto },
    { label: "Cancel", action: this.handleCloseCover },
  ];

  handleEditCoverProfilePicture = () => {
    this.fileInputRef.current.click();
  };

  handleCoverProfilePictureChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const { isJpgOrPng, isSizeValid } = this.checkFile(file);
      if (isJpgOrPng && isSizeValid) {
        this.setState({
          selectedProfileCover: URL.createObjectURL(file),
          selectedProfileCoverFile: file,
          touched: { ...this.state.touched, profileCover: true },
          removeCover: false,
          profileError: "",
        });
        return;
      }
      this.setState({
        profileError: "Cover picture allows only JPEG/PNG files under 5MB.",
      });
    }
  };

  handleEditProfilePicture = () => {
    this.fileInputRef1.current.click();
  };

  checkFile = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isSizeValid = file.size <= 5 * 1024 * 1024;

    return {
      isJpgOrPng,
      isSizeValid,
    };
  };

  handleProfilePictureChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const { isJpgOrPng, isSizeValid } = this.checkFile(file);
      if (isJpgOrPng && isSizeValid) {
        this.setState({
          selectedProfile: URL.createObjectURL(file),
          selectedProfileFile: file,
          touched: { ...this.state.touched, profileImage: true },
          profileError: "",
          removeProfile: false,
        });
        return;
      }
      this.setState({
        profileError: "Profile picture allows only JPEG/PNG files under 5MB.",
      });
    }
  };

  handleCreateListCancel = () => {
    this.setState({
      openCreateList: false,
      title: "",
      description: "",
      titleError: "",
      descriptionError: "",
    });
  };

  handleCreateListDone = () => {
    if (!this.checkCreateListEmptyFields()) return;
    this.handleCreateList();
  };

  handleOpenCreateList = () => {
    this.setState({ openCreateList: true });
  };

  handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = e.target.value;
    const words = inputText.trim().split(/\s+/);
    const wordCount = words.length;
    const isExceeded = wordCount > 100;
    const truncatedText = isExceeded
      ? words.slice(0, 100).join(" ")
      : inputText;
    this.setState({
      description: truncatedText,
      descriptionWordCount: Math.min(wordCount, 100),
      isDescriptionLimitExceeded: isExceeded,
      descriptionError: "",
    });
  };

  handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ title: e.target.value, titleError: "" });
  };

  handleUpdateProfile = async () => {
    if (!this.checkEmptyFields()) return;
    const { name, profileBio, profileImage, profileCover } = this.state.touched;
    const { removeProfile, removeCover, profileError } = this.state;
    if (
      !name &&
      !profileBio &&
      !profileImage &&
      !profileCover &&
      !removeProfile &&
      !removeCover
    )
      return;
    if (profileError) return;
    const formData = new FormData();
    if (name) formData.append("data[full_name]", this.state.name);
    if (profileBio) formData.append("data[bio]", this.state.profileBio);
    if (profileImage)
      formData.append("data[profile_image]", this.state.selectedProfileFile);
    if (profileCover)
      formData.append(
        "data[profile_cover]",
        this.state.selectedProfileCoverFile
      );
    if (this.state.removeProfile) {
      formData.append("data[remove_profile]", this.state.removeProfile);
    }
    if (this.state.removeCover) {
      formData.append("data[remove_profile_cover]", this.state.removeCover);
    }
    this.profileUpdateCallId = await this.apiCall({
      method: "PATCH",
      endPoint: "account_block/accounts/update_account",
      type: "formData",
      body: formData,
    });
  };

  handleGetList = async () => {
    this.getListCallId = await this.apiCall({
      method: "GET",
      endPoint: "account_block/accounts/user_detail",
      contentType: "application/json",
    });
  };

  handleCreateList = async () => {
    const { title, description } = this.state;
    const body = {
      list: {
        title,
        description,
        default_created: false,
      },
    };

    this.createListCallId = await this.apiCall({
      method: "POST",
      endPoint: "account_block/lists",
      contentType: "application/json",
      body,
    });
  };

  handleRemoveProfile = async () => {
    this.setState({
      selectedProfile: null,
      selectedProfileFile: null,
      removeProfile: true,
      profileError: "",
      touched: {
        ...this.state.touched,
        profileImage: false,
      },
    });
  };

  handleRemoveProfileCover = async () => {
    this.setState({
      selectedProfileCover: null,
      selectedProfileCoverFile: null,
      removeCover: true,
      touched: {
        ...this.state.touched,
        profileCover: false,
      },
    });
  };

  handleAddListNavigation = (
    path: string,
    id?: number,
    receipientId?: number
  ) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
    setStorageData("AddressListId", id);
    if (receipientId) {
      setStorageData("receipientId", receipientId);
    }
  };

  unBlockUser = async (userId: any) => {
    this.unBlockedUserCallId = await this.apiCall({
      method: "DELETE",
      endPoint: `bx_block_block_users/block_users/${userId}`,
      contentType: "application/json",
    });
  };

  handleUnBlockCancel = () => {
    this.setState({ openModal: false });
  };

  openUnBlockModal = (userId: number, userName: string) => {
    this.setState({ openModal: true, userId, userName });
  };

  handleOpenEventDeleteModal = (eventId?: number) => {
    this.setState((prevState) => ({
      openEventDeleteModal: !prevState.openEventDeleteModal,
      eventId: eventId || null,
    }));
  };

  handleGetEventList = async () => {
    this.getEventListCallId = await this.apiCall({
      method: "GET",
      endPoint: "bx_block_location/events",
      contentType: "application/json",
    });
  };

  eventDelete = async () => {
    const { eventId } = this.state;
    this.eventDeleteCallId = await this.apiCall({
      method: "DELETE",
      endPoint: `bx_block_location/events/${eventId}`,
      contentType: "application/json",
    });
  };

  // Customizable Area End
}
