// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData, getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
}

export type Event = {
  id: string;
  type: string;
  attributes: {
    name: string;
    is_public: string;
    profile_image: string | null;
    event_name: string;
    event_date: string;
    event_time: string;
    description: string;
    location: string;
    latitude: number;
    longitude: number;
    images: Array<{
      id: number;
      file_name: string;
      file_url: string;
    }>;
    hashtags: Array<string>;
    like: number;
    current_user_like: boolean;
    flag: boolean;
    comments: number;
  };
};


interface S {
  activeFlow: string;
  tabValue: number;
  anchorEl: HTMLElement | null;
  isDialogOpen: boolean,
  isCarouselOpen: boolean,
  dialogHeader: string,
  selectedEvent: Event,
  locationData: any,
  postData: any,
  commentsData: any,
  locationContentData: any,
  eventContentData: any,
  selectedLocation: any,
  addressData: {
    text: string
  },
  eventData: Event[],
  photosData: any,
  postError: string,
  commentsError: string,
  reportedContentError: string,
  photosError: string,
  currentImageIndex: number,
  isToasterOpen: boolean,
  succesMessage: string,
  carouselContent: any,
}

interface SS {
  id: any;
}

export default class ContentModerationController extends BlockComponent<
  Props,
  S,
  SS
> {
  loginDataApiCallId: string | null = "";
  getEventsDataApiCallId: string | null = "";
  getAddressDataApiCallId: string | null = "";
  getPostsDataApiCallId: string | null = "";
  getCommentsDataApiCallId: string | null = "";
  getPhotosDataApiCallId: string | null = "";
  getLocationContentDataApiCallId: string | null = "";
  getEventContentDataApiCallId: string | null = "";
  approveImageApiCallId: string | null = "";
  rejectImageApiCallId: string | null = "";
  removeDataApiCallId: string | null = "";
  restrictDataApiCallId: string | null = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      activeFlow: "",
      tabValue: 0,
      anchorEl: null,
      isDialogOpen: false,
      isCarouselOpen: false,
      dialogHeader: "",
      selectedEvent: {
        id: '',
        type: 'event',
        attributes: {
          name: '',
          is_public: '',
          profile_image: null,
          event_name: '',
          event_date: '',
          event_time: '',
          description: '',
          location: '',
          latitude: 0,
          longitude: 0,
          images: [],
          hashtags: [],
          like: 0,
          current_user_like: false,
          flag: false,
          comments: 0,
        },
      },
      addressData: {
        text: ""
      },
      eventData: [],
      locationData: [],
      selectedLocation: {},
      postData: [],
      commentsData: [],
      photosData: [],
      locationContentData: [],
      eventContentData: [],
      postError: "",
      commentsError: "",
      reportedContentError: "",
      photosError: "",
      currentImageIndex: 0,
      isToasterOpen: false,
      succesMessage: "",
      carouselContent: {}
    };

    this.handleClose = this.handleClose.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getEventContentDataApiCallId:
          this.handleEventContentData(responseJson);
          break;

        case this.getLocationContentDataApiCallId:
          this.handleLocationContentData(responseJson);
          break;

        case this.getCommentsDataApiCallId:
          this.handleCommentsData(responseJson);
          break;

        case this.getPostsDataApiCallId:
          this.handlePostsData(responseJson);
          break;

        case this.getEventsDataApiCallId:
          this.handleEventsData(responseJson);
          break;

        case this.getAddressDataApiCallId:
          this.handleAddressData(responseJson);
          break;

        case this.approveImageApiCallId:
          this.handleApprovedImage(responseJson);
          break;

        case this.rejectImageApiCallId:
          this.handleRejectedImage(responseJson);
          break;

        case this.removeDataApiCallId:
          this.handleRemovedData(responseJson);
          break;
        
        case this.restrictDataApiCallId:
          this.handleRestrictData(responseJson);
          break;
      }

      if (apiRequestCallId === this.getPhotosDataApiCallId) {
        this.handlePhotosData(responseJson)
      }

      if (
        apiRequestCallId === this.loginDataApiCallId &&
        !responseJson.errors
      ) {
        await setStorageData("TOKEN", responseJson.meta.token);
      }
    }
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  contentModerationApiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleEventsData = (responseJson: { "data": Event[], "errors": [{ token: "" }] }) => {
    if (responseJson.data) {
      this.setState({ eventData: responseJson.data });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log('Errors ', responseJson.errors)
    }
  };

  handlePostsData = (responseJson: { "data": any, "errors": [{ token: "" }] }) => {
    if (responseJson.data) {
      if(responseJson.data.length === 0) this.setState({postError: "No post found!", postData: []})
      else this.setState({ postData: responseJson.data, postError: "" });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log('Errors ', responseJson.errors)
    }
  };

  handleCommentsData = (responseJson: { "data": any, "errors": [{ token: "" }] }) => {
    if (responseJson.data) {
      if(responseJson.data.length === 0) this.setState({commentsError: "No comment found!", commentsData: []})
      else this.setState({ commentsData: responseJson.data, commentsError: "" });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log('Errors ', responseJson.errors)
    }
  };

  handleLocationContentData = (responseJson: { "data": any, "error": [{ message: "" }] }) => {
    if (responseJson.data) {
      this.setState({ locationContentData : responseJson.data });
    } else if (responseJson.error) {
      this.setState({reportedContentError: responseJson.error[0].message})
    }
  };

  handleEventContentData = (responseJson: { "data": any, "error": [{ message: "" }] }) => {
    if (responseJson.data) {
      this.setState({ eventContentData : responseJson.data });
    } else if (responseJson.error) {
      this.setState({reportedContentError: responseJson.error[0].message})
    }
  };

  handleAddressData = (responseJson: { "data": any, "errors": [{ token: "" }] }) => {
    if (responseJson.data) {
      this.setState({ locationData: responseJson.data });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log('Errors ', responseJson.errors)
    }
  };

  handlePhotosData = (responseJson: { "data": any, "message": "", "errors": [{ token: "" }] }) => {
    if (responseJson.data) {
      const filteredData = responseJson.data.map((item: any) => ({
        id: item.attributes.id,
        name: item.attributes.name,
        profile_image: item.attributes.profile_image,
        created_at: item.attributes.created_at,
        images: item.attributes.images.filter((image: any) => !image.is_approved)  // Only keep images with is_approved: false
      }));
      if(responseJson.data.length === 0) {
        this.setState({photosError: responseJson.message, photosData: []})}
      else {
        const checkApproval = filteredData?.map((item: any) => {
          return item.images.some((image: any) => !image.is_approved);
        })
        if(checkApproval.includes(true)) this.setState({ photosData: filteredData, photosError: "" });
        else this.setState({photosError: "No attachments found for this location.", photosData: []})
      }
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log('Errors ', responseJson.errors)
    }
  };

  handleApprovedImage = (responseJson: { "message": "", "errors": [{ token: "" }] }) => {
    if (responseJson.message) {
      this.setState({ isToasterOpen: true, succesMessage: responseJson.message, isCarouselOpen: false });
      this.getPhotosData(this.state.selectedLocation?.id);
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log('Errors ', responseJson.errors)
    }
  };

  handleRejectedImage = (responseJson: { "message": "", "errors": [{ token: "" }] }) => {
    if (responseJson.message) {
      this.setState({ isToasterOpen: true, succesMessage: responseJson.message, isCarouselOpen: false });
      this.getPhotosData(this.state.selectedLocation?.id);
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log('Errors ', responseJson.errors)
    }
  };

  handleRemovedData = (responseJson: { "message": "", "errors": [{ token: "" }] }) => {
    if (responseJson.message) {
      this.setState({ isToasterOpen: true, succesMessage: responseJson.message, isDialogOpen: false, anchorEl: null });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log('Errors ', responseJson.errors)
    }
  };

  handleRestrictData = (responseJson: { "message": "", "errors": [{ token: "" }] }) => {
    if (responseJson.message) {
      this.setState({ isToasterOpen: true, succesMessage: responseJson.message, isDialogOpen: false, anchorEl: null });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log('Errors ', responseJson.errors)
    }
  };

  handleContentToaster = () => {
    this.setState({ isToasterOpen: false, succesMessage: "" });
  }

  getEventsData = async () => {
    this.getEventsDataApiCallId = await this.contentModerationApiCall({
      method: "GET",
      endPoint: "bx_block_location/events/moderator_index",
      contentType: "application/json",
    });
  };

  getPostsData = async (id: string) => {
    this.getPostsDataApiCallId = await this.contentModerationApiCall({
      method: "GET",
      endPoint: `bx_block_posts/posts?location_id=${id}`,
      contentType: "application/json",
    });
  };

  getLocationContent = async (id: string) => {
    this.getLocationContentDataApiCallId = await this.contentModerationApiCall({
      method: "GET",
      endPoint: `bx_block_contentflag/contents?location_id=${id}`,
      contentType: "application/json",
    });
  };

  getEventContent = async (id: string) => {
    this.getEventContentDataApiCallId = await this.contentModerationApiCall({
      method: "GET",
      endPoint: `bx_block_contentflag/contents/flaged_event?event_id=${id}`,
      contentType: "application/json",
    });
  };

  getCommentsData = async (id: string) => {
    this.getCommentsDataApiCallId = await this.contentModerationApiCall({
      method: "GET",
      endPoint: `bx_block_comments/comments?commentable_type=BxBlockLocation::Event&commentable_id=${id}`,
      contentType: "application/json"
    });
  };

  getAddressData = async () => {
    this.getAddressDataApiCallId = await this.contentModerationApiCall({
      method: "GET",
      endPoint: "bx_block_location/account_locations",
      contentType: "application/json",
    });
  };

  getPhotosData = async (id: string) => {
    this.getPhotosDataApiCallId = await this.contentModerationApiCall({
      method: "GET",
      endPoint: `bx_block_bulk_uploading/attachments?location_id=${id}`,
      contentType: "application/json",
    });
  };

  approveImage = async (isApprove: boolean, attachmentId: string, recordId?: string ) => {
    let attachmentData = [];
    if(recordId) {
      const filteredData = this.state.photosData.filter((record: any) =>  record.id == recordId);
      attachmentData = filteredData[0].images.map((data: any) => data.id)
    }
    this.approveImageApiCallId = await this.contentModerationApiCall({
      method: "PUT",
      endPoint: `bx_block_bulk_uploading/attachments/approve_attachment`,
      contentType: "application/json",
      body: {
        "is_approved": isApprove,
        "attachment_ids": recordId ? [...attachmentData] : [attachmentId]
      }
    });
  };

  rejectImage = async (id: string, rejectType?: string) => {
    this.rejectImageApiCallId = await this.contentModerationApiCall({
      method: "DELETE",
      endPoint: rejectType ? `bx_block_bulk_uploading/attachments/${id}` : `bx_block_bulk_uploading/attachments/reject_single_attachment?attachment_id=${id}`,
      contentType: "application/json"
    });
  };

  removeData = async (id: string, type: string) => {
    this.removeDataApiCallId = await this.contentModerationApiCall({
      method: "DELETE",
      endPoint: this.state.tabValue === 0 ? `bx_block_${type}s/${type}s/${id}` : `bx_block_contentflag/contents/${id}`,
      contentType: "application/json"
    });
  };

  restrictData = async (id: string) => {
    this.setState({isDialogOpen: false, anchorEl: null})
  };

  async componentDidMount() {
    await this.getEventsData();
    this.getAddressData();
  }

  navigateToTagList = () => {
    this.props.navigation.navigate("CreateContent");
  };

  navigateToAnalyse = () => {
    this.props.navigation.navigate("ContentList");
  };

  handleChange = (event: any, newValue: number) => {
    this.setState({ tabValue: newValue });
    console.log(event)
    if (this.state.activeFlow === "event") {
      if (newValue === 0) this.getCommentsData(this.state.selectedEvent.id);
      else this.getEventContent(this.state.selectedEvent.id)
    }
    else {
      if(newValue === 0) this.getPostsData(this.state.selectedLocation.id);
      else if(newValue === 1) this.getPhotosData(this.state.selectedLocation.id);
      else if(newValue === 2) this.getLocationContent(this.state.selectedLocation.id)
    }
  };

  calculateDate = (text: string) => {

    const date = new Date(text);
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const month = date.toLocaleString('en-US', { month: 'long', timeZone: 'UTC' });

    return `${String(day).padStart(2, '0')} ${month} ${year}`;
  }

  calculateTime = (text: string) => {
    const date = new Date(text);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${hours}:${formattedMinutes} ${period}`;
  }

  getBody = (data: any, tabName: string) => {
    if (tabName === "reportedContent") return data.attributes.reason;
    else {
      if (this.state.activeFlow !== "event") return data.attributes.body;
      else return data.attributes.comment;
    }
  }

  getTime = (data: any, tabName: string) => {
    if (tabName === "reportedContent") return this.calculateTime(data.attributes.date_time);
    else {
      if (this.state.activeFlow !== "event") return data.attributes.time;
      else return this.calculateTime(data.attributes.created_at);
    }
  }

  getDate = (data: any, tabName: string) => {
    if (tabName === "reportedContent") return this.calculateDate(data.attributes.date_time);
    else {
      if (this.state.activeFlow !== "event" || (this.state.activeFlow === "event" && tabName === "reportedContent")) return (data.attributes.date).replace(/-/g, " ");
      else return this.calculateDate(data.attributes.created_at);
    }
  }

  getName = (data: any, tabName: string) => {
    if (tabName === "reportedContent") return data.attributes.flaged_by;
    else return data.attributes.name;
  }

  filteredData = (tabName: string) => {
    if (tabName === "reportedContent") {
      if (this.state.activeFlow === "event") return this.state.eventContentData;
      else return this.state.locationContentData;
    }
    else {
      if (this.state.activeFlow !== "event") return this.state.postData;
      else return this.state.commentsData;
    }
  }

  getError = (tabName: string) => {
    if (tabName === "reportedContent") return this.state.reportedContentError;
    else {
      if(tabName === 'photos') return this.state.photosError;
      else if (this.state.activeFlow !== "event") return this.state.postError;
      else return this.state.commentsError;
    }
  }

  validateError = () => {
    if (this.state.activeFlow === "event") {
      if (this.state.tabValue === 0 && this.state.commentsError !== "") return false;
      else if (this.state.tabValue === 1 && this.state.reportedContentError !== "") return false;
      else return true;
    }
    else return this.getLocationError()
  };

  getLocationError = () => {
    if(this.state.tabValue === 0 && this.state.postError !== "") return false;
    else if(this.state.tabValue === 1 && this.state.photosError !== "") return false;
    else if(this.state.tabValue === 2 && this.state.reportedContentError !== "") return false;
    else return true;
  }

  confirmData = (id: string, type: string) => {
    if(this.state.dialogHeader === "Remove") this.removeData(id, type);
    else this.restrictData(id)
  }
}


// Customizable Area End
