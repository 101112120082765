import React from "react";

import { Typography } from "@builder/component-library";

// Customizable Area Start

import {
  addIcon,
  checked,
  close,
  copy,
  drop,
  dropBlack,
  emailIcon,
  markerIcon,
  share,
  star,
  unCheck,
  unSelectedStar,
} from "./assets";
import {
  LoadScript,
  Marker,
  GoogleMap,
  StreetViewPanorama,
} from "@react-google-maps/api";
import DatePicker from "react-datepicker";
import { styled } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CustomModal from "../../../components/src/CustomModal.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
import {
  Button as ButtonMui,
  Grid,
  Checkbox,
  Avatar,
  CircularProgress,
  Box,
} from "@material-ui/core";
import CustomMenu from "../../../components/src/CustomMenuItem.web";
import Toast from "../../../components/src/CustomSnackbar.web";
import PostCreation from "../../../blocks/postcreation/src/PostCreation.web";
import Header from "../../../blocks/landingpage/src/Header.web";
import Share from "../../../blocks/share/src/Share.web";
import BulkUploading from "../../../blocks/bulkuploading/src/BulkUploading.web";
// Customizable Area End

import CfargooglestreetviewinlaycontentController, {
  Props,
  configJSON,
} from "./CfargooglestreetviewinlaycontentController";

export default class Cfargooglestreetviewinlaycontent extends CfargooglestreetviewinlaycontentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  mapContainerStyle = {
    width: "100%",
    height: "calc(100vh - 77px)",
  };

  starRender() {
    const { favouriteList } = this.state;
    return (
      <img
        src={favouriteList ? star : unSelectedStar}
        alt="star"
        style={webStyle.star}
      />
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box>
          <Header navigation={this.props.navigation} id={""} />
          {this.state.mapKey ? (
            <>
              <LoadScript
                googleMapsApiKey={this.state.mapKey}
                libraries={["places"]}
              >
                <GoogleMap mapContainerStyle={this.mapContainerStyle} zoom={14}>
                  <StreetViewPanorama
                    data-test-id="panoLoad"
                    onLoad={this.handlePanoramaLoad}
                    options={{
                      position: { lat: this.state.lat, lng: this.state.lng },
                      pov: {
                        heading: this.state.heading,
                        pitch: this.state.pitch,
                      },
                      zoom: this.state.zoom,
                      visible: true,
                      enableCloseButton: false,
                    }}
                  />
                  {this.markers.map((marker) => (
                    <Marker
                      key={marker.id}
                      position={marker.position}
                      icon={markerIcon}
                      onClick={() => this.handleMainModal(true, true)}
                      data-test-id="handleMainModal"
                    />
                  ))}
                </GoogleMap>
              </LoadScript>
              {this.state.openMainModal && (
                <ParentModal>
                  <ChildModal>
                    <CloseIcon onClick={() => this.handleMainModal()}>
                      <img src={close} alt="close" />
                    </CloseIcon>
                    <TabsContainer
                      value={this.state.selectedTab}
                      onChange={this.handleTabChange}
                      data-test-id="handleTabChange"
                      TabIndicatorProps={{
                        style: { backgroundColor: "black" },
                      }}
                    >
                      <TabChild
                        label="Home"
                        selected={this.state.selectedTab === 0}
                      />
                      <TabChild
                        label="Guest Book"
                        selected={this.state.selectedTab === 1}
                      />
                      <TabChild
                        label="Photos"
                        selected={this.state.selectedTab === 2}
                      />
                    </TabsContainer>
                    {this.state.selectedTab === 0 && (
                      <div>
                        <Address>{this.state.address}</Address>
                        <Divider />
                        <div style={webStyle.flex}>
                          <ButtonOutline
                            variant="outlined"
                            onClick={this.handleOpenMenu}
                            data-test-id="handleOpenMenu"
                          >
                            ADD TO
                            <img src={drop} style={{ paddingLeft: 8 }} />
                          </ButtonOutline>
                          <div style={webStyle.light}>
                            {this.state.viewCount} views
                            {this.starRender()}
                            <div style={{ marginLeft: 24 }}>
                              <Share
                                navigation={this.props.navigation}
                                id={""}
                                isAction="0"
                                shareIcon={share}
                              />
                            </div>
                          </div>
                        </div>
                        <CustomMenu
                          open={this.state.openMenu}
                          onClose={this.handleCloseMenu}
                          anchorEl={this.state.anchorEl}
                          options={this.menuOptions()}
                          position="left"
                          fontSize={12}
                          width={"min-contant"}
                          data-test-id="handleCloseMenu"
                        />
                        <div style={webStyle.list}>
                          {this.state.allLocationAccountListing?.map(
                            (item: any, index) => (
                              <>
                                <HomeListMain
                                  key={index}
                                  onClick={() =>
                                    this.handleNavigation(
                                      "FriendsProfile",
                                      item.attributes.account_id
                                    )
                                  }
                                  data-test-id="handleNavigation"
                                >
                                  <HomeListImg
                                    src={item.attributes.profile_image}
                                  />
                                  <HomeListInner>
                                    <HomeListName>
                                      {item.attributes.name}
                                    </HomeListName>
                                    <HomeListMutual>
                                      {item.attributes.starting_date}-
                                      {item.attributes.ending_date
                                        ? item.attributes.ending_date
                                        : "Present"}
                                    </HomeListMutual>
                                  </HomeListInner>
                                </HomeListMain>
                                <div style={webStyle.divider} />
                              </>
                            )
                          )}
                        </div>
                        <div style={webStyle.addButton}>
                          <ButtonContained
                            variant="contained"
                            onClick={this.handleAddCancelModal}
                            data-test-id="handleAddCancelModal"
                          >
                            <img src={addIcon} alt="add" />
                          </ButtonContained>
                        </div>
                        <img
                          src={this.state.streetViewImgUrl}
                          alt="img"
                          style={webStyle.ss}
                        />
                        <Toast
                          open={this.state.toastOpen}
                          message={this.state.toastMessage}
                          severity={"success"}
                          onClose={this.handleCloseToast}
                          duration={3000}
                          position={{ vertical: "top", horizontal: "center" }}
                          data-test-id="handleToastClose"
                        />
                      </div>
                    )}
                    {this.state.selectedTab === 1 && (
                      <PostCreation
                        navigation={this.props.navigation}
                        id={""}
                        address={this.state.address}
                      />
                    )}
                    {this.state.selectedTab === 2 && (
                      <BulkUploading
                        navigation={this.props.navigation}
                        id={""}
                      />
                    )}
                  </ChildModal>
                </ParentModal>
              )}
            </>
          ) : (
            <CircularProgress
              style={{ color: customTheme.palette.black.primary }}
            />
          )}
        </Box>
        <CustomModal
          open={this.state.openAddModal}
          cancelButtonText="Cancel"
          doneButtonText="Add"
          onCancel={this.handleAddCancelModal}
          onDone={this.handleAddModal}
          data-test-id="handleAddModal"
          title="Add Your Years"
        >
          {this.state.commonDateError && (
            <TermError>
              <ErrorTypo>{this.state.commonDateError}</ErrorTypo>
            </TermError>
          )}
          <Grid style={{ marginTop: 40 }} container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Label>Starting Date</Label>
              <InputWrapper>
                <DatePicker
                  data-test-id="handleStartDateChange"
                  selected={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Select date"
                  maxDate={new Date()}
                  customInput={
                    <CustomInput>
                      <InputMui
                        type="text"
                        readOnly
                        placeholder="Select a date"
                        isError={!!this.state.startDateError}
                        value={
                          this.state.startDate
                            ? this.state.startDate.toLocaleDateString()
                            : ""
                        }
                      />
                      <IconWrapper>
                        <img src={dropBlack} alt="drop" />
                      </IconWrapper>
                    </CustomInput>
                  }
                />
              </InputWrapper>
              {this.state.startDateError && (
                <ErrorMsg>{this.state.startDateError}</ErrorMsg>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Label disabled={this.state.disabledEndCalender}>
                Ending Date
              </Label>
              <InputWrapper>
                <DatePicker
                  data-test-id="handleEndDateChange"
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Select a date"
                  disabled={this.state.disabledEndCalender}
                  maxDate={new Date()}
                  customInput={
                    <CustomInput>
                      <InputMui
                        type="text"
                        readOnly
                        value={
                          this.state.endDate
                            ? this.state.endDate.toLocaleDateString()
                            : ""
                        }
                        placeholder="Select a date"
                        isError={!!this.state.endDateError}
                        disabled={this.state.disabledEndCalender}
                      />
                      <IconWrapper>
                        <img
                          src={
                            this.state.disabledEndCalender ? drop : dropBlack
                          }
                          alt="drop"
                        />
                      </IconWrapper>
                    </CustomInput>
                  }
                />
              </InputWrapper>
              {this.state.endDateError && (
                <ErrorMsg>{this.state.endDateError}</ErrorMsg>
              )}
            </Grid>
          </Grid>
          <div style={webStyle.CheckboxMain}>
            <CheckboxMain
              checked={this.state.checked}
              disableFocusRipple
              disableRipple
              icon={
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={unCheck}
                  alt="uncheck"
                />
              }
              checkedIcon={<img src={checked} alt="check" />}
              onChange={this.handleCheckBox}
              data-test-id="handleCheckboxChange"
            />
            <div style={webStyle.checkbox}>Currently living here</div>
          </div>
        </CustomModal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 24px",
  },
  light: {
    color: customTheme.palette.grey.extraCoolGrey,
    ...CustomFontVariant.palette.font16400,
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  star: {
    width: 17,
    height: 16,
  },
  divider: {
    borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
    margin: 12,
  },
  addButton: {
    display: "flex",
    justifyContent: "center",
    margin: "56px 0px",
  },
  ss: {
    maxHeight: 144,
    width: "100%",
    height: "100%",
    borderRadius: 24,
  },
  checkbox: {
    ...CustomFontVariant.palette.font14700,
    color: customTheme.palette.grey.secondary,
  },
  CheckboxMain: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    marginTop: 40,
  },
  or: {
    margin: "40px 0px",
    color: customTheme.palette.grey.extraLight,
  },
  list: {
    marginTop: 60,
    padding: "0px 24px",
    height: 160,
    overflow: "auto",
  },
};

const TabsContainer = styled(Tabs)({
  "& .MuiTabs-flexContainer": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
});

const TabChild = styled(Tab)(({ selected }: { selected: boolean }) => ({
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  maxWidth: 242,
  width: "100%",
  color: selected
    ? customTheme.palette.black.primary
    : customTheme.palette.grey.extraCoolGrey,
}));

const Address = styled("div")({
  ...CustomFontVariant.palette.font26400,
  color: customTheme.palette.black.primary,
  marginTop: 54,
  display: "flex",
  justifyContent: "center",
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  margin: "24px 0px",
});

const ButtonOutline = styled(ButtonMui)({
  ...CustomFontVariant.palette.font16400,
  textTransform: "none",
  border: `1px solid${customTheme.palette.grey.light}`,
  color: customTheme.palette.grey.extraCoolGrey,
  maxWidth: 131,
  width: "100%",
  borderRadius: 8,
});

const ButtonOutline1 = styled(ButtonMui)({
  padding: "10px 40px",
  ...CustomFontVariant.palette.font16400,
  textTransform: "none",
  border: `1px solid${customTheme.palette.black.primary}`,
  color: customTheme.palette.black.primary,
  borderRadius: 16,
});

const HomeListMain = styled("div")({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
});

const HomeListName = styled("div")({
  ...CustomFontVariant.palette.font16700,
});

const HomeListMutual = styled("div")({
  ...CustomFontVariant.palette.font14400,
  color: customTheme.palette.grey.main,
});

const HomeListImg = styled(Avatar)({
  width: 56,
  height: 56,
});

const HomeListInner = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  paddingLeft: 10,
});

const ButtonContained = styled(ButtonMui)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
});

const ButtonContained1 = styled(ButtonMui)({
  padding: "10px 60px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
  borderRadius: 16,
  marginTop: 40,
});

const Label = styled("label")(({ disabled }: { disabled?: boolean }) => ({
  ...CustomFontVariant.palette.font14700,
  color: disabled
    ? customTheme.palette.grey.light
    : customTheme.palette.grey.secondary,
}));

const InputMui = styled("input")(({ isError }: any) => ({
  marginTop: 8,
  height: 56,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const CheckboxMain = styled(Checkbox)({
  paddingLeft: 0,
  height: 20,
  width: 20,
  borderRadius: "6px",
  color: customTheme.palette.red.secondary,
  backgroundColor: customTheme.palette.white.primary,
});

const InputWrapper = styled("div")({
  position: "relative",
  width: "100%",
  "& > :first-child": {
    width: "100%",
  },
});

const IconWrapper = styled("div")({
  position: "absolute",
  right: 12,
  top: 36,
  transform: "translateY(-50%)",
  display: "flex",
  alignItems: "center",
});

const CustomInput = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "100%",
  "&:hover": {
    cursor: "pointer",
  },
});

const ErrorMsg = styled(Typography)({
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.red.primary,
  marginTop: 2,
});

const TermError = styled(Box)({
  height: 60,
  width: "100%",
  backgroundColor: customTheme.palette.red.light,
  borderLeft: `4px solid ${customTheme.palette.red.primary}`,
  marginTop: 40,
  borderRadius: 4,
});

const ErrorTypo = styled(Typography)({
  color: customTheme.palette.red.primary,
  ...CustomFontVariant.palette.font12400,
  padding: "20px 16px",
  maxWidth: 328,
});

const ParentModal = styled(Box)({
  position: "fixed",
  background: "#00000080",
  top: 0,
  bottom: 50,
  left: 0,
  right: 0,
  width: "100%",
  height: "100%",
  zIndex: 999,
  overflow: "auto",
});

const ChildModal = styled(Box)({
  width: "100%",
  maxWidth: 820,
  zIndex: 99999,
  background: customTheme.palette.white.main,
  padding: "36px 24px",
  borderRadius: "20px",
  maxHeight: 950,
  margin: "150px auto",
  position: "relative",
});

const CloseIcon = styled("div")({
  position: "absolute",
  top: -18,
  right: -20,
  cursor: "pointer",
});

// Customizable Area End
