import React from "react";
// Customizable Area Start
import CustomModal from "../../../components/src/CustomModal.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
import { Avatar, Button, styled, Box, Typography } from "@material-ui/core";
import { deleteAction, flag, shareAction } from "./assets";
import PostCreationController, {
  Props,
  configJSON,
} from "./PostCreationController.web";
import Likeapost2 from "../../../blocks/likeapost2/src/Likeapost2.web";
import Comments from "../../../blocks/comments/src/Comments.web";
import Toast from "../../../components/src/CustomSnackbar.web";
import Share from "../../../blocks/share/src/Share.web";
import ContentFlag from "../../../blocks/contentflag/src/ContentFlag.web"
// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { showDataCount, allPost } = this.state;
    const isAllDataShown = showDataCount >= allPost.length;
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainDiv>
        <Address>{this.props.address}</Address>
        <Divider />
        <MainContainer>
          <div>
            <Label>{configJSON.newPost}</Label>
            <div style={{ display: "flex", gap: 30, alignItems: "baseline" }}>
              <this.Input
                type="text"
                placeholder="Write your thoughts...."
                value={this.state.newPost}
                onChange={this.handleNewPostChange}
                data-test-id="handleNameChange"
                isError={!!this.state.newPostError}
              />
              <ButtonContained
                variant="contained"
                onClick={this.handleCreatePost}
                data-test-id="handleCreatePost"
              >
                Post
              </ButtonContained>
            </div>
            {this.state.newPostError && (
              <ErrorMsg>{this.state.newPostError}</ErrorMsg>
            )}
          </div>
          <Heading>Posts</Heading>
          <Scroll>
            {this.state.allPost
              ?.slice(0, this.state.showDataCount)
              .map((item: any, index:  number) => (
                <>
                  <div key={index}>
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      onClick={() =>
                        this.handleNavigation(
                          "FriendsProfile",
                          item.attributes.account_id
                        )
                      }
                      data-test-id="handleNavigation"
                    >
                      <HomeListImg src={item.attributes.profile_image} />
                      <HomeListInner>
                        <HomeListName>{item.attributes.name}</HomeListName>
                        <div
                          style={{
                            display: "flex",
                            gap: 8,
                            flexDirection: "column",
                          }}
                        >
                          <HomeDistription>
                            {item.attributes.body}
                          </HomeDistription>
                          <TimeAndDate>
                            <TimeAndDateChild>
                              {item.attributes.time}|{item.attributes.date}
                            </TimeAndDateChild>
                          </TimeAndDate>
                        </div>
                      </HomeListInner>
                    </div>
                    <ActionButton>
                      <Likeapost2
                        navigation={this.props.navigation}
                        id={""}
                        postId={item.id}
                        likeCount={item.attributes.likes}
                        currentUserLike={item.attributes.current_user_like}
                        getAllDataCallBack={this.handleGetPost}
                      />
                      <Comments
                        navigation={this.props.navigation}
                        id={""}
                        postId={item.id}
                        CommentCount={item.attributes.comments}
                        getAllDataCallBack={this.handleGetPost}
                        flagCategory={this.state.flagCategory}
                      />
                      {item.attributes.current_user_post && (
                        <Img
                          src={deleteAction}
                          alt="actionIcon"
                          onClick={this.handleDeleteDoneModal}
                        />
                      )}
                      <Share
                        navigation={this.props.navigation}
                        id={""}
                        isAction="1"
                        shareIcon={shareAction}
                      />
                      <ContentFlag
                       flagIcon={flag}
                       flagContent={this.state.flagCategory}
                       contentId={item.id}
                       flagFor="Post"
                       />
                    </ActionButton>
                  </div>
                  <div style={webStyle.divider} />
                  <CustomModal
                    open={this.state.deleteModalOpen}
                    data-test-id="handleDeleteDoneModal"
                    cancelButtonText="Cancel"
                    doneButtonText="Delete"
                    onCancel={this.handleDeleteCancelModal}
                    onDone={() => this.handleDeletePost(item.id)}
                    title="Delete"
                    subtitle="You are about to delete your post. Are you sure you want to delete this post?"
                  />
                </>
              ))}
            <div style={webStyle.showButton}>
              <this.ShowMoreButton
                disabled={isAllDataShown}
                onClick={this.handleShowMore}
              >
                show more
              </this.ShowMoreButton>
            </div>
          </Scroll>
        </MainContainer>
        <Toast
          open={this.state.toastOpen}
          message={this.state.toastMessage}
          severity={"success"}
          onClose={this.handleCloseToast}
          duration={3000}
          position={{ vertical: "top", horizontal: "center" }}
          data-test-id="handleToastClose"
        />
      </MainDiv>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  divider: {
    borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
    margin: "10px 0px 24px 0px",
  },
  pointer: {
    pointer: "cursor",
  },
  showButton: {
    display: "flex",
    justifyContent: "end",
  },
  comment: {
    display: "flex",
    gap: 24,
    alignItems: "center",
    marginTop: 24,
  },
  or: {
    margin: "40px 0px",
    color: customTheme.palette.grey.extraLight,
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flex1: {
    display: "flex",
    gap: 4,
    flexDirection: "column",
    marginTop: 24,
  },
};

const MainDiv = styled("div")({});

const Address = styled("div")({
  ...CustomFontVariant.palette.font26400,
  color: customTheme.palette.black.primary,
  marginTop: 54,
  display: "flex",
  justifyContent: "center",
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  margin: "24px 0px 40px 0px",
});

const MainContainer = styled("div")({
  padding: "0px 24px",
});

const Label = styled("label")({
  ...CustomFontVariant.palette.font14700,
  color: customTheme.palette.black.primary,
});

const ButtonContained = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
  minHeight: 52,
});

const Heading = styled("div")({
  ...CustomFontVariant.palette.font18700,
  margin: "40px 0px 24px 0px",
});

const HomeListName = styled("div")({
  ...CustomFontVariant.palette.font16700,
});

const HomeDistription = styled("div")({
  ...CustomFontVariant.palette.font16400,
  color: customTheme.palette.black.secondary,
});

const HomeListImg = styled(Avatar)({
  width: 44,
  height: 44,
});

const HomeListInner = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  paddingLeft: 10,
});

const TimeAndDate = styled("div")({
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.grey.main,
});

const TimeAndDateChild = styled("div")({
  display: "flex",
});

const ActionButton = styled("div")({
  display: "flex",
  gap: 16,
  margin: "16px 0px 0px 54px",
});

const Img = styled("img")({
  cursor: "pointer",
});

const ErrorMsg = styled(Typography)({
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.red.primary,
  marginTop: 2,
});

const Scroll = styled("div")({
  maxHeight: 600,
  overflow: "auto",
});

// Customizable Area End
