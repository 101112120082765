import firebase from 'firebase/app';

firebase.initializeApp({
    apiKey: "AIzaSyCYNAScN2SyAo0mTn2Zf83iwYS5iW4DCGE",
    authDomain: "shervinbc-305c7.firebaseapp.com",
    databaseURL: "https://shervinbc-305c7-default-rtdb.firebaseio.com",
    projectId: "shervinbc-305c7",
    storageBucket: "shervinbc-305c7.appspot.com",
    messagingSenderId: "145722660422",
    appId: "1:145722660422:web:44c384e423d7bdbcc11c47",
    measurementId: "G-6XPCLJYKG5"
});

export default firebase;
