import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
interface ApiCallData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {};
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  friendDetails: {
    id: number | null;
    friendId: number | null;
    name: string;
    bio: string;
    profile_image: string;
    profile_cover: string;
    email: string;
    mutual_friends: [];
    status: string | null;
    receipientId: null | number;
    currentUserId: null | number;
    blockedUserId: null | number;
  };
  anchorEl: HTMLElement | null;
  open: boolean;
  openModal: boolean;
  toastOpen: boolean;
  toastMessage: string;
  severity: any;
  openUnblockModal: boolean;
  isCurrentUser: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class FriendsProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFriendProfileCallId: string = "";
  unFriendRequestCallId = "";
  addFriendCallId = "";
  acceptFriendRequestCallId: string = "";
  rejectFriendRequestCallId: string = "";
  blockUserCallId: string = "";
  unBlockUserCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      friendDetails: {
        id: null,
        friendId: null,
        name: "",
        bio: "",
        profile_image: "",
        profile_cover: "",
        email: "",
        mutual_friends: [],
        status: "",
        receipientId: null,
        currentUserId: null,
        blockedUserId: null,
      },
      anchorEl: null,
      open: false,
      openModal: false,
      toastOpen: false,
      toastMessage: "",
      severity: "",
      openUnblockModal: false,
      isCurrentUser: false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getFriendProfileCallId:
          this.getFriendProfileCallIdApi(responseJson);
          break;

        case this.unFriendRequestCallId:
          this.unFriendRequestCallIdApi(responseJson);
          break;

        case this.addFriendCallId:
          this.addFriendCallIdApi(responseJson);
          break;

        case this.acceptFriendRequestCallId:
          this.acceptFriendRequestCallIdApi(responseJson);
          break;

        case this.rejectFriendRequestCallId:
          this.rejectFriendRequestCallIdApi(responseJson);
          break;

        case this.blockUserCallId:
          this.blockUserCallIdApi(responseJson);
          break;

        case this.unBlockUserCallId:
          this.unBlockUserCallIdApi(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getFriendProfileCallIdApi = async (responseJson: any) => {
    if (responseJson.data) {
      const {
        id,
        email,
        bio,
        profile_image,
        profile_cover,
        name,
        mutual_friends,
        connection_id,
        status,
        receipient_id,
        current_user_id,
        blocked_user_id,
      } = responseJson.data.attributes;
      this.setState({
        friendDetails: {
          id,
          friendId: connection_id,
          name,
          email,
          bio,
          profile_image,
          profile_cover,
          mutual_friends,
          status,
          receipientId: receipient_id,
          currentUserId: current_user_id,
          blockedUserId: blocked_user_id,
        },
      });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation1("EmailAccountLoginBlock");
    }
  };

  unFriendRequestCallIdApi = async (responseJson: any) => {
    if (
      responseJson.meta &&
      responseJson.meta.message === "Connection Removed"
    ) {
      const receipientId = await getStorageData("receipientId");
      this.handleGetFriendProfile(receipientId);
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation1("EmailAccountLoginBlock");
    }
  };

  addFriendCallIdApi = async (responseJson: any) => {
    if (
      responseJson.meta &&
      responseJson.meta.message === "Connection created successfully"
    ) {
      const receipientId = await getStorageData("receipientId");
      this.handleGetFriendProfile(receipientId);
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation1("EmailAccountLoginBlock");
    }
  };

  acceptFriendRequestCallIdApi = async (responseJson: any) => {
    if (responseJson.data) {
      const receipientId = await getStorageData("receipientId");
      this.handleGetFriendProfile(receipientId);
      this.setState({
        toastOpen: true,
        toastMessage: "Connection created",
        severity: "success",
      });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation1("EmailAccountLoginBlock");
    }
  };

  rejectFriendRequestCallIdApi = async (responseJson: any) => {
    if (
      responseJson.meta &&
      responseJson.meta.message === "Connection Removed"
    ) {
      this.setState({
        toastOpen: true,
        toastMessage: responseJson.meta.message,
        severity: "error",
      });
      const receipientId = await getStorageData("receipientId");
      this.handleGetFriendProfile(receipientId);
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation1("EmailAccountLoginBlock");
    }
  };

  blockUserCallIdApi = (responseJson: any) => {
    if (responseJson.meta && responseJson.meta.message) {
      this.handleGetFriendProfile(this.state.friendDetails.receipientId);
      this.handleBlockCancel();
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation1("EmailAccountLoginBlock");
    } else if (responseJson.errors && responseJson.errors[0]?.message) {
      this.handleBlockCancel();
      this.setState({
        toastOpen: true,
        toastMessage: responseJson.errors[0]?.message,
        severity: "error",
      });
    }
  };

  unBlockUserCallIdApi = (responseJson: any) => {
    if (responseJson.message) {
      this.handleGetFriendProfile(this.state.friendDetails.receipientId);
      this.handleUnBlockCancel();
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation1("EmailAccountLoginBlock");
    }
  };

  async componentDidMount() {
    const receipientId = await getStorageData("receipientId");
    const id = await getStorageData("id");
    if (id === receipientId) {
      this.setState({ isCurrentUser: true });
    }
    this.handleGetFriendProfile(receipientId);
  }

  apiCall = async (data: ApiCallData) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleNavigation = () => {
    window.history.back();
  };

  handleNavigation1 = (path: string) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  handleGetFriendProfile = async (receipientId: number | null) => {
    await setStorageData("receipientId", receipientId);
    this.getFriendProfileCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_addfriends/connections/show_friend_details?account_id=${receipientId}`,
      contentType: "application/json",
    });
  };

  unFriendRequest = async () => {
    const { friendId } = this.state.friendDetails;
    this.unFriendRequestCallId = await this.apiCall({
      method: "DELETE",
      endPoint: `bx_block_addfriends/connections/${friendId}`,
      contentType: "application/json",
    });
  };

  addFriend = async () => {
    const body = {
      connection: {
        receipient_id: this.state.friendDetails.receipientId,
      },
    };
    this.addFriendCallId = await this.apiCall({
      method: "POST",
      endPoint: `bx_block_addfriends/connections`,
      contentType: "application/json",
      body,
    });
  };

  acceptFriendRequest = async (receipientId: any) => {
    this.acceptFriendRequestCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_addfriends/connections/accept_connection?account_id=${receipientId}`,
      contentType: "application/json",
    });
  };

  rejectFriendRequest = async (connectionId: any) => {
    this.rejectFriendRequestCallId = await this.apiCall({
      method: "DELETE",
      endPoint: `bx_block_addfriends/connections/${connectionId}`,
      contentType: "application/json",
    });
  };

  menuOptions: { label: string; action: () => void }[] = [
    { label: "Report", action: () => console.log("Reported") },
    { label: "Block", action: () => this.handleBlockModalOpen() },
  ];

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  handleOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  blockUser = async () => {
    const body = {
      data: {
        attributes: {
          account_id: this.state.friendDetails.id,
        },
      },
    };
    this.blockUserCallId = await this.apiCall({
      method: "POST",
      endPoint: `/bx_block_block_users/block_users`,
      contentType: "application/json",
      body,
    });
  };

  unBlockUser = async () => {
    this.unBlockUserCallId = await this.apiCall({
      method: "DELETE",
      endPoint: `bx_block_block_users/block_users/${this.state.friendDetails.blockedUserId}`,
      contentType: "application/json",
    });
  };

  handleBlockCancel = () => {
    this.setState({ openModal: false }, () => {
      if (this.state.open) {
        this.setState({ anchorEl: null, open: false });
      }
    });
  };

  handleBlockModalOpen = () => {
    this.setState({ openModal: true });
  };

  handleCloseToast = () => {
    this.setState({ toastOpen: false });
  };

  handleUnBlockCancel = () => {
    this.setState({ openUnblockModal: false });
  };

  openUnBlockModal = () => {
    this.setState({ openUnblockModal: true });
  };

  // Customizable Area End
}
