import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { Platform } from "react-native";
import { getStorageData } from "../../../framework/src/Utilities";
export const baseURL = require("../../../framework/src/config.js").baseURL;

interface IUploadedFile {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      account_id: number;
      files:
        | {
            id: number;
            file_name: string;
            file_url: string;
          }[]
        | null;
      status: string;
    };
  };
  meta: {
    message: string;
  };
}

interface IFile {
  fileCopyUri: string;
  name: string;
  size: number;
  type: string;
  uri: string;
}

interface IDownloadResponse {
  jobId: number;
  statusCode: number;
  bytesWritten: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  filesWeb: File[];
  files: any[];
  uploadedFiles: IUploadedFile[];
  filesStatus: (undefined | "uploading" | "success" | "failed")[];
  openImageModal: boolean;
  selectedImageIndex: number;
  BuldUploadModal: boolean;
  error: string;
  filesData: any;
  locationId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BulkUploadingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  deleteBulkUploadCallId: string = "";
  getGalleryCallId: string = "";
  uploadImageCallId: string = "";
  maxFileSize = 5e6;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      filesWeb: [],
      files: [],
      uploadedFiles: [],
      filesStatus: [],
      openImageModal: false,
      selectedImageIndex: 0,
      BuldUploadModal: false,
      error: "",
      filesData: [],
      locationId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorReponse) {
        this.showAlert("Alert", configJSON.somethingWentWrongMsg);
      }

      switch (apiRequestCallId) {
        case this.createBulkUploadCallId:
          this.createBulkUploadCallIdApi(responseJson);
          break;

        case this.getBulkUploadCallId:
          this.getBulkUploadCallIdApi(responseJson);
          break;

        case this.deleteBulkUploadCallId:
          this.deleteBulkUploadCallIdApi(responseJson);
          break;

        case this.getGalleryCallId:
          this.getGalleryCallIdApi(responseJson);
          break;

        case this.uploadImageCallId:
          this.uploadImageCallIdApi(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  createBulkUploadCallIdApi = (responseJson: any) => {
    let filesStatus = this.state.filesStatus;
    if (responseJson && responseJson.data) {
      filesStatus[filesStatus.length - 1] = "success";
    } else {
      filesStatus[filesStatus.length - 1] = "failed";
    }
    this.setState({ filesStatus });
    this.uploadFile();
  };

  getBulkUploadCallIdApi = (responseJson: any) => {
    if (responseJson !== undefined) {
      !responseJson.errors && this.setState({ uploadedFiles: responseJson });
    }
  };

  deleteBulkUploadCallIdApi = (responseJson: any) => {
    if (responseJson !== undefined) {
      this.showAlert("Alert", configJSON.deletedSuccessMsg);
      this.getUploadedFiles();
    }
  };

  getGalleryCallIdApi = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ filesData: responseJson.data });
    } else if (responseJson.errors[0].token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  uploadImageCallIdApi = (responseJson: any) => {
    if (!responseJson.errors) {
      this.getGalleryData(this.state.locationId);
      this.setState({ BuldUploadModal: false });
    } else if (responseJson.errors[0].token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  componentDidMount = async () => {
    this.getToken();
    const locationId = await getStorageData("locationId");
    this.setState({ locationId });
    this.getGalleryData(locationId);
  };

  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  uploadFile = () => {
    const length = this.isPlatformWeb()
      ? this.state.filesWeb.length
      : this.state.files.length;

    if (length > this.state.filesStatus.length) {
      this.uploadFileSingle(this.state.filesStatus.length);
      this.setState({ filesStatus: [...this.state.filesStatus, "uploading"] });
    }
  };

  uploadFileSingle = (index: number) => {
    const header = {
      token: this.state.token,
    };

    const formData = new FormData();
    formData.append(
      "files[]",
      this.isPlatformWeb()
        ? this.state.filesWeb[index]
        : this.state.files[index]
    );

    const createBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createBulkUploadCallId = createBulkUploadMsg.messageId;

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBulkUploadEndpoint
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    runEngine.sendMessage(createBulkUploadMsg.id, createBulkUploadMsg);
  };

  removeFileWeb = (index: number) => {
    this.setState({
      filesWeb: Array.from(this.state.filesWeb).filter(
        (file, fileIndex) => fileIndex !== index
      ),
    });
  };

  removeFile = (index: number) => {
    this.setState({
      files: Array.from(this.state.files).filter(
        (file, fileIndex) => fileIndex !== index
      ),
    });
  };

  clearAllFile = () => {
    this.setState({ filesWeb: [], files: [], filesStatus: [] });
  };

  onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempFile: File[] = [];
    if (event.target.files) {
      for (let iterator = 0; iterator < event.target.files.length; iterator++) {
        if (event.target.files[iterator].size > this.maxFileSize) {
          this.showAlert(
            "Alert",
            event.target.files[iterator].name + configJSON.fileSizeErrorMsg
          );
        } else {
          tempFile.push(event.target.files[iterator]);
        }
      }
    }
    tempFile.length > 0 &&
      this.setState({
        filesWeb: [...this.state.filesWeb, ...tempFile],
      });
  };

  selectFiles = async () => {
    try {
      const pickerResult = await DocumentPicker.pickMultiple({
        presentationStyle: "fullScreen",
        copyTo: "cachesDirectory",
      });
      const tempFile: DocumentPickerResponse[] = pickerResult.filter((result) => {
          if (result.size && result.size > this.maxFileSize) {
            this.showAlert("Alert", result.name + configJSON.fileSizeErrorMsg);
            return false;
          } else {
            return true;
          }
        });

      this.setState({
        files: [...this.state.files, ...tempFile],
      });
    } catch (error) {
      this.showAlert("Alert", configJSON.downloadingFailedMsg);
    }
  };

  getUploadedFiles = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const getBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBulkUploadCallId = getBulkUploadMsg.messageId;

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBulkUploadEndpoint
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(getBulkUploadMsg.id, getBulkUploadMsg);
  };

  deleteFile = (fileGroupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const deleteBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteBulkUploadCallId = deleteBulkUploadMsg.messageId;

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteBulkUploadEndpoint}/${fileGroupId}`
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteBulkUploadMethod
    );

    runEngine.sendMessage(deleteBulkUploadMsg.id, deleteBulkUploadMsg);
  };

  fileDownloadHandler = (filePath: string, fileName: string) => {
    if (Platform.OS !== "web") {
      try {
        const RNFS = require("react-native-fs");
        const dirPath = this.isPlatformiOS()
          ? RNFS.DocumentDirectoryPath
          : RNFS.DownloadDirectoryPath;
        const toFile = `${dirPath}/${Date.now()}${fileName}`;
        RNFS.downloadFile({
          fromUrl: baseURL + filePath,
          toFile,
        }).promise.then((response: IDownloadResponse) => {
          if (response.statusCode === 200) {
            this.showAlert("Alert", configJSON.downloadedSuccessMsg + toFile);
          } else {
            this.showAlert("Alert", configJSON.downloadingFailedMsg);
          }
        });
      } catch (error) {
        this.showAlert("Alert", configJSON.downloadingFailedMsg);
      }
    }
  };

  handleOpenImageModal = (index: number) => {
    this.setState({ openImageModal: true, selectedImageIndex: index });
  };

  handleCloseImageModal = () => {
    this.setState({ openImageModal: false });
  };

  goToNextImage = () => {
    this.setState((prevState) => {
      const nextIndex =
        (prevState.selectedImageIndex + 1) % this.state.filesData.length;
      return { selectedImageIndex: nextIndex };
    });
  };

  goToPreviousImage = () => {
    this.setState((prevState) => {
      const prevIndex =
        (prevState.selectedImageIndex - 1 + this.state.filesData.length) %
        this.state.filesData.length;
      return { selectedImageIndex: prevIndex };
    });
  };

  handleFiles = (files: FileList) => {
    const newFiles = Array.from(files);
    const allFiles = [...this.state.files, ...newFiles];
    this.setState({ files: allFiles, error: "" });
    const validationError = this.validateFiles(allFiles);
    if (validationError) {
      this.setState({ error: validationError });
    } else {
      this.setState({ files: allFiles, error: "" });
    }
  };

  validateFiles = (files: File[]) => {
    if (files.length > 5) return "You can upload a maximum of 5 files.";
    for (let file of files) {
      if (
        !["image/jpeg", "image/png"].includes(file.type) ||
        file.size > 5 * 1024 * 1024
      ) {
        return "Photo allows only jpeg or png format which is under 5MB";
      }
    }
    return "";
  };

  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      this.handleFiles(event.target.files);
    }
  };

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      this.handleFiles(event.dataTransfer.files);
    }
  };

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  handleRemoveFile = (index: number) => {
    const files = this.state.files.filter((_, i) => i !== index);
    const error = this.validateFiles(files);
    this.setState({ files, error });
  };

  handleBulkUploadModal = () => {
    this.setState((prevState) => ({
      BuldUploadModal: !prevState.BuldUploadModal,
      files: [],
      error: "",
    }));
  };

  getGalleryData = async (locationId: any) => {
    this.getGalleryCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_bulk_uploading/attachments?location_id=${locationId}`,
      contentType: "application/json",
    });
  };

  uploadGalleryImage = async () => {
    if (this.state.files.length === 0) {
      this.setState({ error: "No files selected for upload." });
      return;
    }
    if (this.state.error) return;
    const formData = new FormData();
    formData.append("location_id", this.state.locationId);
    this.state.files.forEach((file) => {
      formData.append("images[]", file);
    });

    this.uploadImageCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_bulk_uploading/attachments",
      type: "formData",
      body: formData,
    });
  };

  handleNavigation = (path: string) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };
  // Customizable Area End
}
