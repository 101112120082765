import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert } from "react-native";
import { getStorageData } from "../../../framework/src/Utilities";
import { InputBase, styled } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: object;
  id?: string;
  // Customizable Area Start
  flagIcon?: string;
  flagContent: any;
  contentId?: string | number;
  flagFor: string;
  isReply?: boolean;
  parentCommentId?: number;
  userToken?: string;
  postID?: number;
  style?: {}
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  contentData: { id: number; reason: string }[];
  showFlag: boolean;
  selectedValue: {
    id: number;
    reason: string;
  };
  showSuccessModal: boolean;
  successmessage: string;
  flagModalOpen: boolean;
  contentReason: string;
  // Customizable Area End
}

interface SS {
  id: number;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContentFlagController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getReasonId: string = "";
  flagContentId: string = "";
  createFlagId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      contentData: [],
      showFlag: false,
      selectedValue: { id: 0, reason: "" },
      showSuccessModal: false,
      successmessage: "",
      flagModalOpen: false,
      contentReason: "",
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getReasonId && responseJson) {
        this.setState({ contentData: responseJson.success[0].flag_reason });
      }

      if (apiRequestCallId === this.flagContentId && !responseJson.errors) {
        this.setState({
          successmessage: responseJson.success[0].message,
          selectedValue: {
            id: 0,
            reason: "",
          },
        });
        Alert.alert("Success", responseJson.success[0].message, [
          {
            text: "OK",
            onPress: () =>
              this.setState({
                selectedValue: {
                  id: 0,
                  reason: "",
                },
                showFlag: false,
              }),
          },
        ]);
      }

      if(apiRequestCallId === this.createFlagId) {
        this.handleFlagData(responseJson)
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  handleFlagIconClick = () => {
    this.setState({
      showFlag: !this.state.showFlag,
    });
  };
  handleFlagButtonClick = () => {
    this.setState({
      showSuccessModal: !this.state.showSuccessModal,
    });
  };
  selectContent = (item: { id: number; reason: string }) => {
    this.setState({
      selectedValue: { id: item.id, reason: item.reason },
    });
  };

  fetchReasons = async (token: string) => {
    const fetchReasonsHeader = {
      "Content-Type": configJSON.contentFlagApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReasonId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllFlagReasonsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchReasonsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReasonApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  doFlagContent(token: string, postid: number, flagid: number) {
    if (!this.state.selectedValue.reason) {
      this.showAlert("Error", configJSON.emptyLableError);
      return false;
    }
    const doFlagContentHeader = {
      "Content-Type": configJSON.contentFlagApiContentType,
      token,
    };
    const httpBody = {
      post_id: postid,
      reason_id: flagid,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.flagContentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.flagPostEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(doFlagContentHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postFlagAPiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area Start
  handleFlagModal = () =>
    this.setState({ flagModalOpen: !this.state.flagModalOpen });

  handleFlagData = (responseJson: any) => {
    if (responseJson.success) {
      this.handleFlagModal();
      this.setState({showSuccessModal: true ,successmessage: responseJson.success[0].message})
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log('Errors ', responseJson.errors)
    }
  };

  createFlagData = async () => {
    const selectedContentData = JSON.parse(JSON.stringify(this.state.contentReason));
    this.createFlagId = await this.contentFlagApiCall({
      method: "POST",
      endPoint: `bx_block_contentflag/contents`,
      contentType: "application/json",
      body: {
        "content":{
          "flag_category_id": selectedContentData?.id,
          "contentable_type": `${this.getContentType(this.props.flagFor)}::${this.props.flagFor}`,
          "contentable_id": Number(this.props.contentId),
          ...(this.props.isReply ? { "parent_id": this.props.parentCommentId } : {})
          }
      }
    });
  };

  getContentType= (flagFor: string) => {
    if (flagFor === "Event" ) return "BxBlockLocation";
    else return `BxBlock${flagFor}s`;
  }

  contentFlagApiCall = async (contentFlagData: any) => {
    const contentFlagToken = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = contentFlagData;
    const contentFlagHeader = { "Content-Type": contentType, 'token': contentFlagToken };
    const contentFlagRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    contentFlagRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(contentFlagHeader)
    );
    contentFlagRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    contentFlagRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? contentFlagRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : contentFlagRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(contentFlagRequest.id, contentFlagRequest);
    return contentFlagRequest.messageId;
  };

  BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  }));
  // Customizable Area End
}
