import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { styled, Typography } from "@material-ui/core";
import customFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  value: number;
  passwordCodeModal: boolean;
  passwordCode: string;
  passwordCodeError: string;
  passwordModal: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
  isMinLength: boolean;
  newPassword: string;
  confirmPassword: string;
  confirmPasswordError: string;
  newPasswordError: string;
  newPasswordHide: boolean;
  confirmPasswordHide: boolean;
  emailCodeModal: boolean;
  emailCode: string;
  emailCodeError: string;
  emailModal: boolean;
  newEmail: string;
  emailError: string;
  deleteCodeModal: boolean;
  deleteModal: boolean;
  deleteCode: string;
  deleteError: string;
  logOutModal: boolean;
  time: number;
  isTimerRunning: boolean;
  activeMenu: string;
  drawerOpen: boolean;
  passwordVeriyToken: string;
  toast: boolean;
  OkPasswordMsg: string;
  emailVeriyToken: string;
  OkEmailMsg: string;
  OkEmailModal: boolean;
  deleteModalSuccessfully: boolean;
  privacyKey: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountSettingsWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendPasswordOtpApiCallId: string = "";
  verifyPasswordOtpApiCallId: string = "";
  newPasswordSubmitApiCallId: string = "";
  sendEmailOtpApiCallId: string = "";
  verifyEmailOtpApiCallId: string = "";
  deleteAccountApiCallId: string = "";
  getPrivacyKeyApiCallId: string = "";
  timerId: number | NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      value: 1,
      passwordCodeModal: false,
      passwordCode: "",
      passwordCodeError: "",
      passwordModal: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      isMinLength: false,
      newPassword: "",
      confirmPassword: "",
      confirmPasswordError: "",
      newPasswordError: "",
      newPasswordHide: false,
      confirmPasswordHide: false,
      emailCodeModal: false,
      emailCode: "",
      emailCodeError: "",
      emailModal: false,
      newEmail: "",
      emailError: "",
      deleteCodeModal: false,
      deleteModal: false,
      deleteCode: "",
      deleteError: "",
      logOutModal: false,
      time: 0,
      isTimerRunning: true,
      activeMenu: "",
      drawerOpen: false,
      passwordVeriyToken: "",
      toast: false,
      OkPasswordMsg: "",
      emailVeriyToken: "",
      OkEmailMsg: "",
      OkEmailModal: false,
      deleteModalSuccessfully: false,
      privacyKey: ""
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.sendPasswordOtpApiCallId:
          this.sendPasswordOtpApi(responseJson);
          break;

        case this.verifyPasswordOtpApiCallId:
          this.verifyPasswordOtpApi(responseJson);
          break;

        case this.newPasswordSubmitApiCallId:
          this.newPasswordSubmitApi(responseJson);
          break;

        case this.sendEmailOtpApiCallId:
          this.sendEmailOtpApi(responseJson);
          break;

        case this.verifyEmailOtpApiCallId:
          this.verifyEmailOtpApi(responseJson);
          break;

        case this.deleteAccountApiCallId:
          this.deleteAccountApi(responseJson);
          break;
        
        case this.getPrivacyKeyApiCallId:
          this.getPrivacyKeyApi(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.redirectInvalidAccount();
    this.handlePrivacyKey();
  }

  sendPasswordOtpApi = (responseJson: any) => {
    if (responseJson.meta) {
      this.setState({ passwordVeriyToken: responseJson.meta.token });
      this.setState({ passwordCodeModal: true });
      this.setState({ time: 120, isTimerRunning: true }, async () => {
        this.startTimer();
      });
    }
  };

  verifyPasswordOtpApi = (responseJson: any) => {
    if (responseJson.messages) {
      this.setState(
        {
          passwordCodeModal: false,
          passwordCode: "",
          passwordCodeError: "",
          time: 0,
          isTimerRunning: false,
        },
        () => {
          this.stopTimer();
        }
      );
      this.handlePasswordOpenModal();
    } else {
      this.setState({ passwordCodeError: responseJson.errors[0].otp });
    }
  };

  newPasswordSubmitApi = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        passwordModal: false,
        newPassword: "",
        confirmPassword: "",
        hasLowerCase: false,
        hasNumber: false,
        hasUpperCase: false,
        isMinLength: false,
        toast: true,
        OkPasswordMsg: responseJson.message,
      });
    }
  };

  sendEmailOtpApi = (responseJson: any) => {
    if (responseJson.meta) {
      this.setState({
        emailVeriyToken: responseJson.meta.token,
        emailModal: false,
        emailError: "",
        emailCodeModal: true,
      });
      this.setState({ time: 120, isTimerRunning: true }, async () => {
        this.startTimer();
      });
    } else {
      this.setState({ emailError: responseJson.errors[0].account });
    }
  };

  verifyEmailOtpApi = (responseJson: any) => {
    if (responseJson.messages) {
      this.setState(
        {
          OkEmailMsg: responseJson.messages,
          emailCodeModal: false,
          newEmail: "",
          emailCode: "",
          emailCodeError: "",
          OkEmailModal: true,
          time: 0,
          isTimerRunning: false,
        },
        () => {
          this.stopTimer();
        }
      );
    } else {
      this.setState({ emailCodeError: responseJson.errors[0].otp });
    }
  };

  deleteAccountApi = async (responseJson: any) => {
    if (responseJson.message) {
      await removeStorageData("id");
      await removeStorageData("token");
      this.handleDeleteCancel();
      this.setState({ deleteModalSuccessfully: true });
    }
  };

  getPrivacyKeyApi = async (responseJson: any) => {
    if (responseJson.privacy_setting) {
      this.setState({ privacyKey: responseJson.privacy_setting.data.attributes.is_account });
    }
  };

  redirectInvalidAccount = async () => {
    const authToken = await getStorageData("token");
    if (!authToken) {
      this.handleLogInNavigation("EmailAccountLoginBlock");
    }
  };

  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleToastClose = () => {
    this.setState({ toast: false });
  };

  handleChange = async (newValue: any) => {
    if(newValue === 2) this.handlePrivacyKey()
    this.setState({ value: newValue });
  };

  menu = [
    {
      id: 1,
      name: "Account Settings",
    },
    {
      id: 2,
      name: "Privacy Settings",
    },
    {
      id: 3,
      name: "Notification Settings",
    },
  ];

  startTimer = () => {
    this.timerId = setInterval(() => {
      this.setState(
        (prevState) => ({ time: prevState.time - 1 }),
        () => {
          if (this.state.time === 0) {
            this.stopTimer();
            this.setState({ isTimerRunning: false });
          }
        }
      );
    }, 1000);
  };

  stopTimer(): void {
    if (this.timerId) {
      clearInterval(this.timerId as number);
    }
  }

  handleSendPasswordOtp = async () => {
    if (this.state.time === 0) {
      this.sendPasswordOtpApiCallId = await this.apiCall({
        method: "POST",
        endPoint: "bx_block_forgot_password/otps",
        contentType: "application/json",
        body: {
          data: {
            is_password: true,
          },
        },
      });
    }
  };

  handlePasswordCodeOpenModal = () => {
    this.handleSendPasswordOtp();
  };

  handlePasswordCodeCancel = () => {
    this.setState(
      {
        passwordCodeModal: false,
        passwordCode: "",
        passwordCodeError: "",
        time: 0,
        isTimerRunning: false,
      },
      () => {
        this.stopTimer();
      }
    );
  };

  handlePasswordCodeConfirm = () => {
    this.handleVerifyPasswordOtp();
  };

  handleVerifyPasswordOtp = async () => {
    this.verifyPasswordOtpApiCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_forgot_password/otp_confirmations",
      contentType: "application/json",
      body: {
        data: {
          otp_code: this.state.passwordCode,
          token: this.state.passwordVeriyToken,
        },
      },
    });
  };

  handlePasswordCode = (event: any) => {
    if (this.isDigit(event.target.value)) {
      this.setState({ passwordCode: event.target.value });
    }
  };

  handlePasswordOpenModal = () => {
    this.setState({ passwordModal: true });
  };

  handlePasswordCancel = () => {
    this.setState({
      passwordModal: false,
      newPassword: "",
      confirmPassword: "",
      hasLowerCase: false,
      hasNumber: false,
      hasUpperCase: false,
      isMinLength: false,
      newPasswordError: "",
      confirmPasswordError: "",
    });
  };

  handlePasswordConfirm = () => {
    this.handleNewPasswordSubmit();
  };

  handleNewPasswordSubmit = async () => {
    if (!this.checkEmptyFields()) return;
    if (this.state.confirmPasswordError || this.state.newPasswordError) {
      return;
    }
    const { hasLowerCase, hasNumber, hasUpperCase, isMinLength } = this.state;
    if (!hasUpperCase || !hasLowerCase || !hasNumber || !isMinLength) {
      this.setState({
        newPasswordError: "Password does not meet requirements",
      });
      return;
    }
    this.newPasswordSubmitApiCallId = await this.apiCall({
      method: "PATCH",
      endPoint: "bx_block_profile/passwords",
      contentType: "application/json",
      body: {
        data: {
          new_password: this.state.newPassword,
        },
      },
    });
  };

  handleOkEmailCodeConfirm = () => {
    this.setState({ OkEmailModal: false });
  };

  handleNewPasswordHide = () => {
    this.setState({ newPasswordHide: !this.state.newPasswordHide });
  };

  handleConfirmNewPasswordHide = () => {
    this.setState({ confirmPasswordHide: !this.state.confirmPasswordHide });
  };

  checkEmptyFields = () => {
    const { newPassword, confirmPassword } = this.state;
    let isValid = true;
    if (!newPassword) {
      this.setState({
        newPasswordError: "New password is required",
      });
      isValid = false;
    }

    if (!confirmPassword) {
      this.setState({
        confirmPasswordError: "Confirm password is required",
      });
      isValid = false;
    }

    return isValid;
  };

  validatePassword = (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isMinLength = password?.length >= 8;

    this.setState({
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      isMinLength,
    });
  };

  handleNewPasswordChange = (event: any) => {
    const password = event.target.value;
    this.setState({ newPassword: password, newPasswordError: "" }, () => {
      this.validatePassword(password);
      if (this.state.confirmPassword !== "") {
        this.validateConfirmPassword();
      }
    });
  };

  handleConfirmPasswordChange = (event: any) => {
    const confirmPassword = event.target.value;
    this.setState({ confirmPassword }, () => {
      this.validateConfirmPassword();
    });
  };

  validateConfirmPassword = () => {
    const newPassword = this.state.newPassword;
    const confirmPassword = this.state.confirmPassword;
    const confirmPasswordError =
      newPassword !== confirmPassword ? "Password doesn't match" : "";
    this.setState({ confirmPasswordError });
  };

  handleVerifyEmailOtp = async () => {
    this.verifyEmailOtpApiCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_forgot_password/otp_confirmations",
      contentType: "application/json",
      body: {
        data: {
          otp_code: this.state.emailCode,
          token: this.state.emailVeriyToken,
        },
      },
    });
  };

  handleEmailCodeCancel = () => {
    this.setState(
      {
        emailCodeModal: false,
        newEmail: "",
        emailCode: "",
        emailCodeError: "",
        time: 0,
        isTimerRunning: false,
      },
      () => {
        this.stopTimer();
      }
    );
  };

  handleEmailCodeConfirm = () => {
    this.handleVerifyEmailOtp();
  };

  handleEmailCode = (event: any) => {
    if (this.isDigit(event.target.value)) {
      this.setState({ emailCode: event.target.value });
    }
  };

  isDigit = (inputValue: string): boolean => {
    const regex = /^\d*$/;
    return regex.test(inputValue);
  };

  handleEmailChange = (event: any) => {
    this.setState({ newEmail: event.target.value, emailError: "" });
  };

  handleEmailOpenModal = () => {
    this.setState({ emailModal: true });
  };

  validateEmail = (email: string) => {
    const isValid = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$/.test(
      email
    );
    this.setState({
      emailError: isValid ? "" : "Invalid email",
    });
    return isValid;
  };

  handleSendEmailOtp = async () => {
    if (this.state.time === 0) {
      this.sendEmailOtpApiCallId = await this.apiCall({
        method: "POST",
        endPoint: "bx_block_forgot_password/otps",
        contentType: "application/json",
        body: {
          data: {
            new_email: this.state.newEmail,
          },
        },
      });
    }
  };

  handleEmailCancel = () => {
    this.setState({ emailModal: false, emailError: "", newEmail: "" });
  };

  handleEmailConfirm = () => {
    if (!this.state.newEmail) {
      this.setState({ emailError: "Email is Required" });
      return;
    }
    if (!this.validateEmail(this.state.newEmail)) return;
    this.handleSendEmailOtp();
  };

  handleDeleteOpenModal = () => {
    this.setState({ deleteModal: true });
  };

  handleDeleteCancel = () => {
    this.setState({ deleteModal: false });
  };

  handleDeleteConfirm = () => {
    this.handleDeleteAccount();
  };

  handleDeleteSuccessfully = () => {
    this.setState({ deleteModalSuccessfully: false });
    window.location.replace("EmailAccountRegistrationWeb");
  };

  handleLogOutOpenModal = () => {
    this.setState({ logOutModal: true });
  };

  handleLogOutCancel = () => {
    this.setState({ logOutModal: false });
  };

  handleDeleteAccount = async () => {
    this.deleteAccountApiCallId = await this.apiCall({
      method: "DELETE",
      endPoint: "bx_block_profile/passwords",
      contentType: "application/json",
    });
  };

  handlePrivacyKey = async () => {
    this.getPrivacyKeyApiCallId = await this.apiCall({
      method: "GET",
      endPoint: "/bx_block_privacy_setting/privacy_settings/user_privacy_settings",
      contentType: "application/json",
    });
  };

  handleLogOutConfirm = async () => {
    await removeStorageData("id");
    await removeStorageData("token");
    await removeStorageData("profile");
    window.location.replace("EmailAccountLoginBlock");
    this.setState({ logOutModal: false });
  };

  handleLogInNavigation = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  handleMenuClick = (id: any) => {
    this.setState({ activeMenu: id });
  };

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  Menu = styled(Typography)(({ value }: any) => ({
    borderRadius: 10,
    padding: "16px",
    backgroundColor: value ? customTheme.palette.grey.primary : "transparent",
    ...customFontVariant.palette.font16700,
    color: customTheme.palette.black.primary,
    cursor: "pointer",
  }));

  ReSesnd = styled("div")(({ time }: { time: number }) => ({
    ...customFontVariant.palette.font16700,
    cursor: time === 0 ? "pointer" : "not-allowed",
  }));


Input = styled("input")(({ isError }: any) => ({
  height: 56,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));
  // Customizable Area End
}
