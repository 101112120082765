Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.acceptAll = "/";
exports.getReasonApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postFlagAPiMethodType = "POST";
exports.contentFlagApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentFlag";
exports.labelBodyText = "ContentFlag Body";
exports.emptyLableError = "Select Valid reason";
exports.successFlagMessage = "Post Flagged Sucessfully";
exports.errorFlagMessage = "Unable to Flag the content Please Try Again";
exports.emptyReasonMessage = "No Reason Found";
exports.getPostsApiEndPoint = "bx_block_posts/posts";
exports.getAllFlagReasonsEndPoint = "bx_block_contentflag/flag_reasons";
exports.flagPostEndPoint = "bx_block_contentflag/contents";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End