import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {
  getStorageData,
  setStorageData,
  removeStorageData,
} from "framework/src/Utilities";
interface ApiCallData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {};
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  tabValue: number;
  currentPage: number;
  friendsPerPage: number;
  search: string;
  FriendsList: [];
  totalCount: number;
  toastOpen:boolean;
  severity:any,
  toastMessage:string,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class FriendsListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFriendListCallId: string = "";
  acceptFriendRequestCallId: string = "";
  rejectFriendRequestCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      tabValue: 0,
      currentPage: 1,
      friendsPerPage: 8,
      search: "",
      FriendsList: [],
      totalCount: 1,
      toastOpen:false,
      severity:"",
      toastMessage:"",
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.getFriendListCallId) {
          if (responseJson.data) {
            this.setState({
              FriendsList: responseJson.data,
              currentPage: responseJson.meta.current_page,
              totalCount: responseJson.meta.total_pages,
            });
          } else if (responseJson.errors && responseJson.errors[0]?.token) {
            this.handleNavigation("EmailAccountLoginBlock");
          } else {
            this.setState({ FriendsList: [] });
          }
        }
      }
      switch (apiRequestCallId) {
        case this.acceptFriendRequestCallId:
          this.acceptFriendRequestCallIdApi(responseJson);
          break;

        case this.rejectFriendRequestCallId:
          this.rejectFriendRequestCallIdApi(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  acceptFriendRequestCallIdApi = (responseJson: any) => {
    if (responseJson.data) {
      const { tabValue, search, currentPage, friendsPerPage } = this.state;
      this.handleGetFriendProfile(
        tabValue === 1,
        search,
        currentPage,
        friendsPerPage,
        tabValue === 2
      );
      this.setState({
        toastOpen: true,
        toastMessage: "Connection created",
        severity: "success",
      });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  rejectFriendRequestCallIdApi = (responseJson: any) => {
    if (
      responseJson.meta &&
      responseJson.meta.message === "Connection Removed"
    ) {
      const { tabValue, search, currentPage, friendsPerPage } = this.state;
      this.handleGetFriendProfile(
        tabValue === 1,
        search,
        currentPage,
        friendsPerPage,
        tabValue === 2
      );
      this.setState({
        toastOpen: true,
        toastMessage: responseJson.meta.message,
        severity: "error",
      });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  async componentDidMount() {
    const blockedTab = await getStorageData("blockedTab");
    if (blockedTab) this.setState({ tabValue: 3 });
    const { tabValue, search, currentPage, friendsPerPage } = this.state;
    this.handleGetFriendProfile(
      tabValue === 1,
      search,
      currentPage,
      friendsPerPage,
      tabValue === 2
    );
  }

  async componentWillUnmount() {
    await removeStorageData("blockedTab");
  }

  apiCall = async (data: ApiCallData) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleNavigation = async (path: string, receipientId?: number) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
    if (receipientId) {
      setStorageData("receipientId", receipientId);
    }
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const { search, currentPage, friendsPerPage } = this.state;

    this.setState({ tabValue: newValue }, () => {
      this.handleGetFriendProfile(
        newValue === 1,
        search,
        currentPage,
        friendsPerPage,
        newValue === 2
      );
    });
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const { tabValue, currentPage, friendsPerPage } = this.state;
    this.setState({ search: value });
    this.debouncedSearch(
      tabValue === 1,
      value,
      currentPage,
      friendsPerPage,
      tabValue === 2
    );
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    const { tabValue, search, friendsPerPage } = this.state;
    this.setState({ currentPage: page });
    this.handleGetFriendProfile(
      tabValue === 1,
      search,
      page,
      friendsPerPage,
      tabValue === 2
    );
  };

  handleGetFriendProfile = async (
    tabValue?: boolean,
    search?: string,
    currentPage?: number,
    friendsPerPage?: number,
    pending?: boolean
  ) => {
    if (this.state.tabValue === 3) {
      this.setState({ FriendsList: [] });
      return;
    }
    this.getFriendListCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_addfriends/connections?recently_added=${tabValue}&search=${search}&page=${currentPage}&per_page=${friendsPerPage}&pending=${pending}`,
      contentType: "application/json",
    });
  };

  debouncedSearch = this.debounce(this.handleGetFriendProfile, 300);

  debounce(func: (...args: any) => any, delay: number) {
    let timeout: NodeJS.Timeout | undefined;
    return function(...args: any) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  acceptFriendRequest = async (receipientId: any) => {
    this.acceptFriendRequestCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_addfriends/connections/accept_connection?account_id=${receipientId}`,
      contentType: "application/json",
    });
  };

  rejectFriendRequest = async (connectionId: any) => {
    this.rejectFriendRequestCallId = await this.apiCall({
      method: "DELETE",
      endPoint: `bx_block_addfriends/connections/${connectionId}`,
      contentType: "application/json",
    });
  };

  handleCloseToast = () => {
    this.setState({ toastOpen: false });
  };

  // Customizable Area End
}
